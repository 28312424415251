import { useCallback} from "react";
import Dropmenu from "./Dropmenu";
import PortalPopup from "./PortalPopup";
import styles from "./styles/SidebarRight.module.css";
import {BsArrowDownCircleFill} from "react-icons/bs"
import Carousel from "./Carousel";

const SidebarRight = ({ latestTransactions,handleClickAddAccount, handleClickDropmenu, loading, t ,balances,addresses, cryptoName,setDropmenuOpen,currentIndex ,currentAccount,isDropmenuOpen,handleCurrentAccount}) => {
  const openDropmenu = useCallback(() => {
    setDropmenuOpen(true);
  }, []);

  const closeDropmenu = useCallback(() => {
    setDropmenuOpen(false);
  }, []);
  const parsedBalances = balances && balances !== "" ? JSON.parse(balances) : {};

  const portfolioCoins = [
    {
      src: "/vector3.svg",
      name: "Bitcoin",
      symbol: "BTC",
      balance: `${parsedBalances["BTC"] && parsedBalances["BTC"][currentIndex] !== undefined & parsedBalances["BTC"][0].balance.balance!== 0
      ? parsedBalances["BTC"][0].balance.balance.toFixed(9)
      : 0
      ? parsedBalances["BTC"][0].balance
      : "0"}${" "}
      ${"BTC"}`,
    },
    {
      src: "/vector4.svg",
      name: "Ethereum",
      symbol: "ETH",
      balance: `${parsedBalances["ETH"] && parsedBalances["ETH"][currentIndex] !== undefined & parsedBalances["ETH"][0].balance!== 0
      ? parsedBalances["ETH"][0].balance.toFixed(9)
      : 0
      ? parsedBalances["ETH"][0].balance
      : "0"}${" "}
      ${"ETH"}`,
    },
    {
      src: "/vector5.svg",
      name: "Binance",
      symbol: "BNB",
      balance:  `${parsedBalances["BNB"] && parsedBalances["BNB"][currentIndex] !== undefined & parsedBalances["BNB"][0].balance!== 0
      ? parsedBalances["BNB"][0].balance.toFixed(9)
      : 0
      ? parsedBalances["BNB"][0].balance
      : "0"}${" "}
      ${"BNB"}`,
    },
    {
      src: "/solana.svg",
      name: "Solana",
      symbol: "SOL",
      balance: `${parsedBalances["SOL"] && parsedBalances["SOL"][currentIndex] !== undefined & parsedBalances["SOL"][0].balance!== 0
      ? parsedBalances["SOL"][0].balance.toFixed(9)
      : 0
      ? parsedBalances["SOL"][0].balance
      : "0"}${" "}
      ${"SOL"}`,
    },
    {
      src: "/monero.svg",
      name: "Monero",
      symbol: "XMR",
      balance:`${parsedBalances["XMR"] && parsedBalances["XMR"][currentIndex] !== undefined & parsedBalances["XMR"][0].balance.balance!== 0
      ? parsedBalances["XMR"][0].balance.balance.toFixed(9)
      : 0
      ? parsedBalances["XMR"][0].balance.balance
      : "0"}${" "}
      ${"XMR"}`,
    },
  ];

  return (
    <>
      <div className={styles.sidebarRight}>
        <div className={styles.account1}>{t("SidebarRight.address")} <BsArrowDownCircleFill style={{color:"#c8b568"}}/></div>
        <div className={styles.xe235e24Parent}>
          <b className={styles.coinType}>{cryptoName} <sup >{currentIndex.toString()}</sup></b>
          <div className={styles.xe235e24}>{currentAccount? currentAccount: "Loading..."}</div>
          <img className={styles.vectorIcon} alt="" src="/vector2.svg" />
        </div>
        <div className={styles.portfolio}>{t("SidebarRight.portfolio")}</div>
        <div className={styles.active} />
        <div className={styles.firstAddresses}>
          <p className={styles.pStyle}>{t("SidebarRight.firstAddress")} <BsArrowDownCircleFill style={{color:"#c8b568"}}/></p>
          <Carousel flag={"PortfolioCoins"} items={portfolioCoins} delay={2000} loading={loading}/>
        </div>

        
        <div className={styles.dividerWrapper}>
          <div className={styles.divider} />
        </div>
        
        <div className={styles.portfolio}>{t("SidebarRight.recentActivity")}</div>
        <div className={styles.active} />
        <div className={styles.attivitRecenti}>

          <Carousel flag={"Transactions"} items={latestTransactions} delay={2000} addresses={addresses} t={t}/>

          <div className={styles.dividerWrapper}>
          <div className={styles.divider1} />
          </div>
        </div>
        <img
          className={styles.frecciaGiuIcon}
          alt=""
          src="/freccia-giu.svg"
          onClick={openDropmenu}
        />
      </div>
      {isDropmenuOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeDropmenu}
        >
          <Dropmenu t={t} onClose={closeDropmenu} handleClickAddAccount={handleClickAddAccount} handleCurrentAccount={handleCurrentAccount} addresses={addresses} handleClickDropmenu={handleClickDropmenu}/>
        </PortalPopup>
      )}
    </>
  );
};

export default SidebarRight;
