
import styles from "./styles/ChangeDevice.module.css"
const ChangeDevice = ({t}) => {
     return(
        <>
        <div className={styles.specific_page}>
            <div className={styles.watermark}>
                <div className={styles.parentDiv}>
                    <img src="warning.png" className={styles.icon}/>
                    <h2 className={styles.h2Style}>{t("ChangeDevice.title")}</h2>
                    <p className={styles.pStyle}>{t("ChangeDevice.subtitle")}<br/>{t("ChangeDevice.subtitle2")}</p>
                </div>
            </div>
        </div>
        </>
     )
};

export default ChangeDevice