import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { Suspense } from "react";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./global.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./i18n";
import { BaseUrlProvider } from "./context/BaseUrlContext";
import { TwoFAProvider } from "./context/TwoFAProvider";
import { AuthProvider, useAuth } from "./context/AuthProvider";
const container = document.getElementById("root");
const root = createRoot(container);
function MainComponent() {
  const { isInitialized } = useAuth();
  const [rerender, setRerender] = useState(false);

 
  useEffect(() => {
    if (isInitialized) {
      setRerender(true);
    }
  }, [isInitialized]);


  return (
    <>
      {isInitialized && (
        <BrowserRouter>
          <Suspense fallback={<div>Loading...</div>}>
            <BaseUrlProvider>
              <App />
            </BaseUrlProvider>
          </Suspense>
        </BrowserRouter>
      )}
    </>
  );
}

root.render(
  <AuthProvider>
    <TwoFAProvider>
      <MainComponent />
    </TwoFAProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
