import { useNavigate } from "react-router-dom";
import styles from "../pages/styles/Dashboard.module.css";
import { useCallback, useState } from "react";

export const SidebarLeft = ({t,handleLogout}) => {
  const [currentRoot, setCurrentRoot] = useState(window.location.pathname);
  const walletPath="/"
  const storiaPath=t("Paths./history")
  const supportoPath=t("Paths./support")
  const impostazioniPath=t("Paths./settings")

  const navigate = useNavigate();
  const onStoriaContainerClick = useCallback(() => {
    setCurrentRoot(storiaPath);
    navigate(storiaPath);
  }, [navigate]);

  const onSupportoContainerClick = useCallback(() => {
    setCurrentRoot(supportoPath);
    navigate(supportoPath);
  }, [navigate]);

  const onImpostazuoniContainerClick = useCallback(() => {
    setCurrentRoot(impostazioniPath);
    navigate(impostazioniPath);
  }, [navigate]);

  const onEsciContainerClick = useCallback(() => {
    window.open("google.com");
  }, []);

  const onWalletContainerClick = useCallback(() => {
    setCurrentRoot(walletPath);
    navigate(walletPath);
  }, [navigate]);

  return (
    <>
      <div className={styles.sidebarLeft}>
        <div className={styles.sidebarLeftTop}>
          <img className={styles.logoIcon} alt="" src="/logoYellow.png" />
          <div className={styles.menu}>
          <div className={`${styles.wallet} ${currentRoot === walletPath ? styles.active : ''}`} onClick={onWalletContainerClick}>
            {currentRoot === walletPath ?
              <img className={styles.walletIcona} alt="" src="/wallet-icona.svg" />
              :
              <img className={styles.walletIcona} alt="" src="/wallet-icon.svg" />
            }
            <div className={styles.wallet1}>{t("SidebarLeft.wallet")}</div>
          </div>
            <img className={styles.menuChild} alt="" src="/line-31.svg" />
            <div className={`${styles.storia} ${currentRoot === storiaPath ? styles.active : ''}`} onClick={onStoriaContainerClick}>
            {currentRoot === storiaPath ?
              <img className={styles.storiaIcon} alt="" src="/storia-icona.svg" />
              :
              <img className={styles.storiaIcon} alt="" src="/storia-icon.svg" />
            }
            <div className={styles.transazioni}>{t("SidebarLeft.transazioni")}</div>
          </div>
            <img className={styles.menuChild} alt="" src="/line-4.svg" />
            <div className={`${styles.supporto} ${currentRoot === supportoPath ? styles.active : ''}`} onClick={onSupportoContainerClick}>
            {currentRoot === supportoPath ?
              <img className={styles.supportoIcon} alt="" src="/supporto-icona.svg" />
              :
              <img className={styles.supportoIcon} alt="" src="/supporto-icon.svg" />
            }
            <div className={styles.transazioni}>{t("SidebarLeft.supporto")}</div>
          </div>
            <img className={styles.menuChild} alt="" src="/line-5.svg" />
            <div className={`${styles.impostazioni} ${currentRoot === impostazioniPath ? styles.active : ''}`} onClick={onImpostazuoniContainerClick}>
            {currentRoot === impostazioniPath ?
              <img className={styles.impostazioniIcon} alt="" src="/impostazioni-icona.svg" />
              :
              <img className={styles.impostazioniIcon} alt="" src="/impostazioni-icon.svg" />
            }
            <div className={styles.transazioni}>{t("SidebarLeft.impostazioni")}</div>
          </div>
            <img className={styles.menuChild} alt="" src="/line-5.svg" />
            <div className={styles.storia} onClick={handleLogout}>
            <img className={styles.esciIcon} alt="" src="/esci-icon.svg" />
            <div className={styles.transazioni}>{t("SidebarLeft.esci")}</div>
          </div>
          </div>
        </div>
      </div>
    </>
  );
};
