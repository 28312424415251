import { useEffect, useState, useCallback, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { BaseUrlContext } from "../context/BaseUrlContext";
import axios from "axios";

const VerifyTokenChangePassword = ({ t }) => {
  const { baseUrl } = useContext(BaseUrlContext);
  const navigate = useNavigate();

  useEffect(() => {
    const VERIFY_URL = "/api/verifyTokenChangePassword";
    const tokenParam = new URLSearchParams(window.location.search).get("token");

    // Verifica se il parametro token è presente
    if (tokenParam) {
      // Effettua la chiamata API per verificare il token
      const fetchData = async () => {
        try {
          const response = await axios.get(
            baseUrl + `${VERIFY_URL}?token=${tokenParam}&app=true`
          );

          const data = response.data; // Usa response.data invece di response.json()

          if (data.result === true) {
            // Se result è true, reindirizza a /login?conferma=true
            navigate(`/recover?token=${tokenParam}`);
          } else {
            // Se result è false, reindirizza a /login
            navigate("/login");
          }
        } catch (error) {
          console.error("Errore durante la chiamata API:", error);
          // Gestisci eventuali errori qui e reindirizza a /login in caso di errore
          navigate("/login");
        }
      };

      fetchData();
    } else {
      // Se il parametro token non è presente, reindirizza a /login
      navigate("/login");
    }
  }, [navigate, t]);

  return (
    <>
      {/* Contenuto del componente VerifyTokenChangePassword */}
    </>
  );
};

export default VerifyTokenChangePassword;
