import { useContext, useEffect, useState } from "react";
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./styles/RecoverPassword.module.css";
import axios from "axios";
import { BaseUrlContext } from "../context/BaseUrlContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const CHANGE_PSW_URL = "/api/changePasswordForNoLoggedUser";

const RecoverPassword = ({ t }) => {
  const navigate = useNavigate();
  const { baseUrl } = useContext(BaseUrlContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Retrieve email and token from query parameters
  const token = queryParams.get("token");

  const [newPwd, setNewPwd] = useState("");
  const [validNewPwd, setValidNewPwd] = useState(false);
  const [newPwdFocus, setNewPwdFocus] = useState(false);

  const [confirmNewPwd, setConfirmNewPwd] = useState("");
  const [validConfirmNewPwd, setValidConfirmNewPwd] = useState(false);
  const [confirmNewPwdFocus, setConfirmNewPwdFocus] = useState(false);
  
 
  useEffect(() => {
    setValidNewPwd(PWD_REGEX.test(newPwd));
    setValidConfirmNewPwd(newPwd === confirmNewPwd);
  }, [newPwd, confirmNewPwd]);



  const handleSubmitChangePassword = async (e) => {
    e.preventDefault();
    const v2 = PWD_REGEX.test(newPwd);
    console.log(v2)
    if (!v2) {
      setErrMsg(t("ServerErrors.invalidEntry"));
      return;
    }
    try {
        console.log(token)
        const response = await axios.post(
        baseUrl + CHANGE_PSW_URL,
        {
          token: token,
          new_password: newPwd,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_SECRET_APP}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
    navigate("/login")
    } catch (err) {
        console.error(err)
    }
  };

  return (
    <>
        <Helmet>
        <title>{t("Recover.metaTitle")}</title>
        <meta name="description" content={t("Recover.metaDescription")} />
        </Helmet>
        <div className={styles.specific_page}>
          <section className={styles.watermark}>
            <div className={styles.watermark}>
              <form
                onSubmit={handleSubmitChangePassword}
                className={styles.formDiv}
              >
                <h2 style={{ textAlign: "center" }}>
                  {t("Login.changePassword")}
                </h2>

                <label htmlFor="new_password" style={{ color: "#9f8b3b" }}>
                  {t("Login.newPasswordLabel")}
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={validNewPwd ? styles.valid : styles.hide}
                  />
                  <FontAwesomeIcon
                    icon={faTimes}
                    className={
                      validNewPwd || !newPwd ? styles.hide : styles.invalid
                    }
                  />
                </label>
                <input
                  type="password"
                  id="new_password"
                  className={styles.labelDiv}
                  onChange={(e) => setNewPwd(e.target.value)}
                  value={newPwd}
                  required
                  aria-describedby="newpwdnote"
                  onFocus={() => setNewPwdFocus(true)}
                  onBlur={() => setNewPwdFocus(false)}
                />
                <p
                  id="newpwdnote"
                  className={
                    newPwdFocus && !validNewPwd
                      ? styles.instructions
                      : styles.offscreen
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} />{" "}
                  {t(`Registration.passwordHandle`)}{" "}
                  <span aria-label="exclamation mark">!</span>{" "}
                  <span aria-label="at symbol">@</span>{" "}
                  <span aria-label="hashtag">#</span>{" "}
                  <span aria-label="dollar sign">$</span>{" "}
                  <span aria-label="percent">%</span>
                </p>

                <label
                  htmlFor="confirm_new_password"
                  style={{ color: "#9f8b3b" }}
                >
                  {t("Login.confirmNewPasswordLabel")}
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={
                      confirmNewPwd && validConfirmNewPwd
                        ? styles.valid
                        : styles.hide
                    }
                  />
                  <FontAwesomeIcon
                    icon={faTimes}
                    className={
                      validConfirmNewPwd || !confirmNewPwd
                        ? styles.hide
                        : styles.invalid
                    }
                  />
                </label>
                <input
                  type="password"
                  id="confirm_new_password"
                  className={styles.labelDiv}
                  onChange={(e) => setConfirmNewPwd(e.target.value)}
                  value={confirmNewPwd}
                  required
                  aria-describedby="confirmnewpwdnote"
                  onFocus={() => setConfirmNewPwdFocus(true)}
                  onBlur={() => setConfirmNewPwdFocus(false)}
                />
                <p
                  id="confirmnewpwdnote"
                  className={
                    confirmNewPwdFocus && !validConfirmNewPwd
                      ? styles.instructions
                      : styles.offscreen
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                  {t("Registration.confirmPasswordHandle")}
                </p>

                <button
                  className={`${
                    !validNewPwd || !validConfirmNewPwd
                      ? styles.buttonDivDisabled
                      : styles.buttonDiv
                  }`}
                  disabled={!validNewPwd || !validConfirmNewPwd}
                  onClick={handleSubmitChangePassword}
                >
                  {t("Login.changePassword")}
                </button>
                <p style={{ marginBottom: 0, marginTop: -10, fontSize: 12 }}>
                  {t("Login.phraseChange")}
                </p>
              </form>
            </div>
          </section>
        </div>
    </>
  );
};

export default RecoverPassword;
