import { useRef, useState, useEffect, useContext } from "react";
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import styles from "./styles/Login.module.css";
import { BaseUrlContext } from "../context/BaseUrlContext";
import { useAuth } from "../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useCookies } from "react-cookie";
import SuccessPop from "../components/SuccessPop";
import FailurePopUp from "../components/FailurePopUp";
import { AiOutlineClose } from "react-icons/ai";
import {AiOutlineEye} from "react-icons/ai";
import { SiAuthy } from "react-icons/si"; //SiAurelia SiAuth0 SiAuthy
import {AiOutlineEyeInvisible} from "react-icons/ai";
import { FiArrowLeft } from "react-icons/fi";


const Login = ({ t, confirmParam, activationParam }) => {
  const navigate = useNavigate();
  const userRef = useRef();
  const errRef = useRef();

  const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const LOGIN_URL = "/api/login";
  const SEND_EMAIL_URL = "/api/sendChangePasswordEmail";
  const VERIFY_OTP_URL="/api/verifyOTP"

  const [verifyError, setVerifyError] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [accessToken, setAccessToken] = useState(null);
  const [show2FAForm, setShow2FAForm] = useState(false);
  const [is2FA, setIs2FA] = useState(false);
  const [failure, setFailure] = useState(false);

  const [cookies, setCookie] = useCookies(["2FA"]);
  const [inputType, setInputType] = useState("password");
  const { baseUrl } = useContext(BaseUrlContext);
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [pwd, setPwd] = useState("");

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [showRecover, setShowRecover] = useState(false);
  const { setAuthToken } = useAuth();
  
  const [showResetFormPassword, setShowResetFormPassword] = useState(false);

  let browser = navigator.userAgent;

  const toggleInputType = () => {
    setInputType(inputType === "password" ? "text" : "password");
  };


  const handleErrorSendEmail = () => {
    setFailure(false)
    setErrMsg("")
  };

  const handleConfirmation = () => {
    navigate("/login")
  };

  const handleActivation = () => {
    navigate("/login")
  };

  const handleChangeEmail = (e) => {
    setErrMsg("")
    setEmail(e.target.value)
  };

  const handleChangePassword = (e) => {
    setErrMsg("")
    setPwd(e.target.value)
  };

  const handleVerifyToken = async () => {
    try {
      const response = await axios.post(
        baseUrl + VERIFY_OTP_URL,
        {
          otp: authCode
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      console.log(response)
      return true
    }catch(error){
      console.log(error)
      setVerifyError(error.response.data.message)
      return false
    }
  }
  
  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  const handleShowRecover= () => {
    setEmail("")
    setShowRecover(!showRecover)
  };

  const handleAuthCode = (event) => {
    setVerifyError("")
    let value = event.target.value;
    const regex = /^[0-9]{0,6}$/;
    if (regex.test(value)) {
      setAuthCode(value);
    }
  };

  const handleResetFormPassword = () => {
     setShowResetFormPassword(!showResetFormPassword)
     setShowRecover(!showRecover)
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    const v1 = EMAIL_REGEX.test(email);
    if (!v1) {
      setErrMsg(t("ServerErrors.invalidEntry"));
      return;
    }
    console.log(process.env.REACT_APP_SECRET_APP)
    try {
      const response = await axios.post(
        baseUrl + LOGIN_URL,
        {
          username: email,
          password: pwd,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_SECRET_APP}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      const accessToken = response.data.data.token;
      let is2FALog= response.data.data.twofa
      setAccessToken(accessToken)
      setIs2FA(is2FALog)
      if(is2FALog==1){
        setCookie("2FA",true)
        setShow2FAForm(true)
      } else{
        setCookie("2FA",false)
        handleNavigation(accessToken);
      }
    } catch (err) {
      if(err.response){
        setErrMsg(err.response.data.message);
      } else{
        setErrMsg(err.message)
      }
      errRef.current.focus();
    }
  };

  const handleNavigation = async (accessToken) => {
    if(is2FA==1){
      let isValid=await handleVerifyToken()
      console.log(isValid)
      if(!isValid) return    
    }
    setAuthToken(accessToken);
    navigate("/");
    setSuccess(true);
    setEmail("");
    setPwd("");
  };

  const handleSubmitSendEmail = async (e) => {
    
    e.preventDefault();
    // if button enabled with JS hack
    const v1 = EMAIL_REGEX.test(email);
    if (!v1) {
      console.error(t("ServerErrors.invalidEntry"));
      return;
    }
    
    try {
      const response = await axios.post(
        baseUrl + SEND_EMAIL_URL,
        {
          email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_SECRET_APP}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      console.log(response)
      setShowResetFormPassword(true)
      setSuccess(true)
    } catch (err) {
      setFailure(true)
      if(err.response)
        setErrMsg(err.response.data.message)
      else{
        console.log(err)
        setErrMsg("Network Error, check your connection")
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("Login.metaTitle")}</title>
      </Helmet>
      {(confirmParam===true || confirmParam==="true") &&
          <SuccessPop title={t("SuccessPopUp.ConfirmEmailTitle")} message={t("SuccessPopUp.ConfirmEmail")} handleSuccess={handleConfirmation} t={t}/>
      }

      {(activationParam===true || activationParam==="true") &&
          <SuccessPop message={t("SuccessPopUp.ActivationSubTitle")} handleSuccess={handleActivation} t={t}/>
      }

      {failure &&
      <FailurePopUp message={errMsg} handleFailure={handleErrorSendEmail} t={t}/>
      }
      <div className={styles.specific_page}>
        {showRecover ? ( showResetFormPassword?
          (success?
            <SuccessPop title={t("SuccessPopUp.LoginTitle")} message={t("SuccessPopUp.LoginSubTitle")} handleSuccess={handleResetFormPassword}/>:
            <FailurePopUp message={errMsg} handleFailure={handleResetFormPassword} t={t}/>
          ):
          
          <div className={styles.specific_page}>

          { /* Recover Password Form */}
          <section className={styles.watermark}>
            <div className={styles.watermark}>
              <form
                className={styles.recoverDiv}
              >
              <div className={styles.rowDiv}>
                <FiArrowLeft
                  className={styles.firstRowIcon}
                  onClick={handleShowRecover}
                />
                <h2 style={{ textAlign: "center" }}>{t("Recover.title")}</h2>
              </div>
                <p
                  style={{
                    marginBottom: 0,
                    marginTop: -10,
                    fontSize: 15,
                    alignSelf: "center",
                  }}
                >
                  {t("Recover.subtitle")}
                </p>
                
                <label
                  htmlFor="email"
                  style={{ color: "#9f8b3b", alignSelf: "center" }}
                >
                  Email:
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={validEmail ? styles.valid : styles.hide}
                  />
                  <FontAwesomeIcon
                    icon={faTimes}
                    className={
                      validEmail || !email ? styles.hide : styles.invalid
                    }
                  />
                </label>
                <input
                  type="text"
                  id="email"
                  className={styles.labelDiv}
                  ref={userRef}
                  autoComplete="off"
                  onChange={handleChangeEmail}
                  value={email}
                  required
                  aria-invalid={validEmail ? "false" : "true"}
                  aria-describedby="uidnote"
                  onFocus={() => setEmailFocus(true)}
                  onBlur={() => setEmailFocus(false)}
                />
                <p
                  id="uidnote"
                  className={
                    emailFocus && email && !validEmail
                      ? styles.instructions
                      : styles.offscreen
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                  {t("Registration.emailHandle")}
                </p>
                <button
                  className={`${
                    !validEmail ? styles.buttonDivDisabled : styles.buttonDiv
                  }`}
                  disabled={!validEmail}
                  onClick={handleSubmitSendEmail}
                >
                  {t("Recover.button")}
                </button>
                
              </form>
            </div>
          </section>
        </div>
        ) : (
          <section className={styles.watermark}>

            { /* Registration Form */}

            {errMsg ? (
              <p ref={errRef} className={styles.errmsg} aria-live="assertive">
                {errMsg}
              </p>
            ) : (
              <p
                ref={errRef}
                className={styles.offscreen}
                aria-live="assertive"
              >
                {errMsg}
              </p>
            )}

            {errRef && (
              <p
                ref={errRef}
                className={styles.offscreen}
                aria-live="assertive"
              >
                {t("ServerErrors.success")}
              </p>
            )}

            <div className={styles.formDiv}>
              <h2 style={{ textAlign: "center" }}>{t("Login.signin")}</h2>

              <label htmlFor="email" style={{ color: "#9f8b3b" }}>
                Email:
                <FontAwesomeIcon
                  icon={faCheck}
                  className={validEmail ? styles.valid : styles.hide}
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className={
                    validEmail || !email ? styles.hide : styles.invalid
                  }
                />
              </label>
              <input
                type="text"
                id="email"
                className={styles.labelDiv}
                ref={userRef}
                autoComplete="off"
                onChange={handleChangeEmail}
                value={email}
                required
                aria-invalid={validEmail ? "false" : "true"}
                aria-describedby="uidnote"
                onFocus={() => setEmailFocus(true)}
                onBlur={() => setEmailFocus(false)}
              />
              <p
                id="uidnote"
                className={
                  emailFocus && email && !validEmail
                    ? styles.instructions
                    : styles.offscreen
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                {t("Registration.emailHandle")}
              </p>

              <label htmlFor="password" style={{ color: "#9f8b3b" }}>
                {t("Login.passwordLabel")}
              </label>
              <div className={styles.flexDivPassword}>
                <input
                  type={inputType}
                  id="password"
                  className={styles.labelDiv}
                  onChange={handleChangePassword}
                  value={pwd}
                  required
                  aria-describedby="pwdnote"
                />
                {inputType === "password" ? (
                    <AiOutlineEyeInvisible size={25} className={styles.AiOutlineEye} onClick={toggleInputType} />
                  ) : (
                    <AiOutlineEye size={25} className={styles.AiOutlineEye} onClick={toggleInputType} />
                  )
                }
              </div>
              <button
                className={`${
                  !validEmail ? styles.buttonDivDisabled : styles.buttonDiv
                }`}
                disabled={!validEmail}
                onClick={handleSubmit}
              >
                {t("Login.signInButton")}
              </button>
              <p style={{ marginBottom: 0 }}>
                {t("Login.question")}
                <span className={styles.line}>
                  <a className={styles.line2} href={t("Paths./registration")}>
                    {t("Login.signup")}
                  </a>
                </span>
              </p>

              <p style={{ marginBottom: 0 }}>
                {t("Login.passwordQuestion")}
                <span
                  className={styles.line2}
                  onClick={handleShowRecover}
                >
                  {t("Login.recover")}
                </span>
              </p>

              <p style={{ marginBottom: 0 }}>
                {t("Login.importQuestion")}
                <span className={styles.line}>
                  <a className={styles.line2} href={t("Paths./restorekeys")}>
                    {t("Login.importMasternode")}
                  </a>
                </span>
              </p>
            </div>
            {show2FAForm && (
              <>
                { /* 2FA Form for Google Authenticator */}

                <div className={styles.containerCard}>
                <div className={styles.connectionCard}>
                    <div className={styles.rowConnection}>
                        <div>
                        <h1>Inserisci il Codice 2FA</h1>
                        </div>
                        <div>
                        <AiOutlineClose
                            className={styles.firstRowIcon}
                            onClick={()=>setShow2FAForm(false)}
                        />
                        </div>
                    </div>
                    <div className={styles.columnConnection}>
                        <p style={{fontSize:18,textAlign:"center"}}>{t('2FACardLogin.title')} <strong>Bankount: Wallet</strong>.</p>
                    </div>
                  <div className={styles.row}>
                    <SiAuthy size={35} style={{marginRight:10}}/>
                    <input
                      type="text"
                      id="code"
                      value={authCode}
                      onChange={handleAuthCode}
                      placeholder={t("GoogleAuth.placeholder")}
                      className={styles.input}
                      required
                    />
                  </div>
                    <hr style={{ margin: 5, width: 400 }} />
                    <div className={styles.flexDiv}>
                        <button className={styles.submitButton2} onClick={()=>setShow2FAForm(false)}>{t("Disactivate2FA.button2")}</button>
                        <button className={styles.submitButton} onClick={()=>handleNavigation(accessToken)}>{t("Disactivate2FA.button1")}</button>
                    </div>
                    {verifyError && <p style={{color:"red"}}>{verifyError}</p>}
                    
                </div>
                </div>
              </>
            )}
          </section>
        )}
      </div>
    </>
  );
};

export default Login;
