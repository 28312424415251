import React, { createContext, useState } from "react";

export const BaseUrlContext = createContext();

export const BaseUrlProvider = ({ children }) => {
  const defaultUrl = process.env.REACT_APP_MIDDLEWARE_BASE_URL
  console.log(defaultUrl)
  
  const [baseUrl, setBaseUrl] = useState(defaultUrl);

  return (
    <BaseUrlContext.Provider value={{ baseUrl, setBaseUrl }}>
      {children}
    </BaseUrlContext.Provider>
  );
};