import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector"


i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    lng: "it",
    fallbackLng: "it",
    resources: {
      en: {
        translation: require("./locales/en/translation.json")
      },
      it: {
        translation: require("./locales/it/translation.json")
      },
      // altre lingue...
    }
  });

export default i18next