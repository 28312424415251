import React, { useState, useRef } from "react";
import styles from "../components/styles/RestoreKeysPage.module.css";
import { BaseUrlContext } from "../context/BaseUrlContext";
import { useContext } from "react";
import axios from "axios";
import Registration from "../pages/Registration";
function RestoreKeysPage({ t }) {
  const IMPORT_URL = "/api/generateMasternodeFromMmenomnic";
  const [seedWords, setSeedWords] = useState(Array(12).fill(""));
  const [lastEmptyWordIndex, setLastEmptyWordIndex] = useState(null);
  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [accessToken, setAccessToken] = useState(null);
  const { baseUrl } = useContext(BaseUrlContext);
  const inputRefs = useRef([]);

  const handleInputChange = (index, event) => {
    const value = event.target.value;
    setErrMsg("");
    if (/^[a-zA-Z]*$/.test(value)) {
      // Controlla se il valore contiene solo lettere
      const newSeedWords = [...seedWords];
      newSeedWords[index] = value;
      setSeedWords(newSeedWords);
      setLastEmptyWordIndex(null); // Resetta l'indice dell'ultima parola vuota quando l'utente modifica un input
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    for (let i = 0; i < seedWords.length; i++) {
      if (seedWords[i].trim() === "") {
        setLastEmptyWordIndex(i);
        inputRefs.current[i].focus();
        return;
      }
    }
    try {
      const response = await axios.post(
        baseUrl + IMPORT_URL,
        {
          mnemonic: seedWords.join(" "),
          network: process.env.REACT_APP_NETWORK_TYPE,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_SECRET_APP}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      setSuccess(response.data.result);
      setAccessToken(response.data.data)
      setErrMsg(response.data.message);
    } catch (err) {
      if (err.response) {
        console.log(err)
        setErrMsg(err.response.data.message);
      } else {
        setErrMsg("Network Error, check your connection");
      }
    }
  };

  return (
    <div className={styles.specific_page}>
      {success ? (
        <Registration shouldImportMasternode={accessToken} t={t} />
      ) : (
        <>
          <img className={styles.logoIcon} alt="" src="/logoYellow.png" />
          <p className={styles.parentpStyle}>{t("RestoreKeysPage.title")}</p>
          <form onSubmit={handleSubmit}>
            <div className={styles.flexDiv}>
              <div className={styles.input_grid}>
                {seedWords.map((word, index) => (
                  <div key={index} className={styles.inputWrapper}>
                    <input
                      ref={(el) => (inputRefs.current[index] = el)}
                      type="text"
                      value={word}
                      placeholder={`${t("RestoreKeysPage.word")} ${index + 1}`}
                      onChange={(event) => handleInputChange(index, event)}
                    />
                    {lastEmptyWordIndex === index && (
                      <div className={styles.errorPopup}>
                        Please enter word {index + 1}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              {errMsg && <p className={styles.childpStyle} > {errMsg} </p>}
              <button type="submit" className={styles.buttonStyle}>
                Restore
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
}

export default RestoreKeysPage;
