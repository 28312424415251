import { useContext, useState } from "react";
import styles from "./styles/TransferCard.module.css";
import { useTranslation } from 'react-i18next';
import { BaseUrlContext } from "../context/BaseUrlContext";
import {ClipLoader} from "react-spinners";
import TokenTransfer from "./TokenTransfer";
import CryptoTransfer from "./CryptoTransfer";

const TransferCard = ({index, isCryptoTransfer, isTokenTransfer,balances,handleSuccessClick, handleViewCard, returnBackForCrypto,returnBackFromToken, cryptoName,addresses}) => {
  
  const { t } = useTranslation();
  const { baseUrl } = useContext(BaseUrlContext);

  return (
    <>
      {isCryptoTransfer && <CryptoTransfer t={t} balances={balances} handleSuccessClick={handleSuccessClick} index={index} addresses={addresses} returnBackForCrypto={returnBackForCrypto} handleViewCard={handleViewCard} baseUrl={baseUrl} cryptoName={cryptoName}/>}

      {isTokenTransfer && <TokenTransfer t={t} handleSuccessClick={handleSuccessClick} index={index} addresses={addresses} returnBackFromToken={returnBackFromToken} handleViewCard={handleViewCard} baseUrl={baseUrl} cryptoName={cryptoName}/>}

      
    </>
  );
};

export default TransferCard;
