import { useEffect, useState } from "react";
import axios from "axios";
import { FiArrowLeft } from "react-icons/fi";
import { RiArrowDropDownLine } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import styles from "./styles/TransferCard.module.css";
import { useAuth } from "../context/AuthProvider";
import { ClipLoader } from "react-spinners";
import SuccessPop from "./SuccessPop";
import FailurePopUp from "./FailurePopUp";

const TokenTransfer = ({
  returnBackFromToken,
  handleViewCard,
  t,
  handleSuccessClick,
  baseUrl,
  cryptoName,
  addresses,
  index,
}) => {
  const [destinationAddress, setDestinationAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [tokenBalance, setTokenBalance] = useState();
  const [tokens, setTokens] = useState([]);
  const { token } = useAuth();
  const EXCHANGE_TOKEN_URL = "/api/exchangeTokenBetweenSameBc";
  const GET_TOKEN_LIST = "/api/getListOfSupportedTokens";
  const GET_TOKEN_BALANCE = "/api/getTokens";

  useEffect(() => {
    const fetchData = async () => {
      await getListOfSupportedTokens(cryptoName);
    };
    fetchData();
  }, []);

  const handleExchange = async (tokenname) => {
    setLoading(true);
    await exchangeTokenBetweenBlockchain(tokenname);
    setLoading(false);
  };

  const handleDestinationAddressChange = (event) => {
    setDestinationAddress(event.target.value);
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleSuccess = () => {
    handleViewCard();
    setSuccess(!success);
  };

  const handleFailure = () => {
    handleViewCard();
    setFailure(!failure);
  };

  const exchangeTokenBetweenBlockchain = async (symbol) => {
    try {
      const response = await axios.post(
        baseUrl + EXCHANGE_TOKEN_URL,
        {
          chain: cryptoName,
          symbol: symbol,
          fromaddress: addresses[cryptoName][index],
          toaddress: destinationAddress,
          amount: amount,
          network: process.env.REACT_APP_NETWORK_TYPE,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Assicurati di includere "Bearer " prima del token
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      setSuccess(true);
    } catch (err) {
      console.log(err);
      setFailure(err);
    }
  };

  const getListOfSupportedTokens = async () => {
    try {
      const response = await axios.post(
        baseUrl + GET_TOKEN_LIST,
        {
          chain: cryptoName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      setTokens(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getTokenBalance = async (tokenname, address) => {
    try {
      console.log(cryptoName)
      const response = await axios.post(
        baseUrl + GET_TOKEN_BALANCE,
        {
          chain: cryptoName,
          symbol: tokenname,
          address: address,
          network: process.env.REACT_APP_NETWORK_TYPE
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      console.log(response)
      setTokenBalance(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {success && (
        <SuccessPop
          message={t("SuccessPopUp.TransferTitle")}
          handleSuccess={handleSuccess}
          handleSuccessClick={handleSuccessClick}
          t={t}
        />
      )}
      {failure && (
        <FailurePopUp
          message={t("FailurePopUp.TransferTitle")}
          handleFailure={handleFailure}
          t={t}
        />
      )}
      {!loading ? (
        <div className={styles.containerCard}>
          <div
            className={`${styles.connectionCard} ${
              isActive ? styles.active : styles.inactive
            }`}
          >
            <div className={styles.rowConnection}>
              <div>
                <FiArrowLeft
                  className={styles.firstRowIcon}
                  onClick={returnBackFromToken}
                />
              </div>
              <div>
                <p>{t("OperationCard.cardTokenTitle")}</p>
              </div>
              <div>
                <AiOutlineClose
                  className={styles.firstRowIcon}
                  onClick={handleViewCard}
                />
              </div>
            </div>
            <div
              className={styles.dropdownBtn}
              onClick={() => setIsActive(!isActive)}
            >
              <p className={styles.pStyle}>
                {t("OperationCard.chooseSendToken")}{" "}
                <span style={{ color: "#001e66", fontWeight: 700 }}>
                  {t("OperationCard.token")}
                </span>{" "}
                {t("OperationCard.sendToken")}
              </p>
              <div
                className={styles.dropdownClick}
                onClick={() => {
                  getListOfSupportedTokens();
                  setIsActive(!isActive);
                }}
                onBlur={() => setIsActive(false)}
              >
                <img
                  src={selected.image}
                  style={{ height: 25, marginRight: 5, marginLeft: 25 }}
                />
                <p style={{ color: "black", marginBottom: 5 }}>
                  {selected.symbol
                    ? selected.symbol.toUpperCase()
                    : "Seleziona Token"}
                </p>
                <RiArrowDropDownLine size={25} style={{ color: "black" }} />
              </div>
              {isActive && (
                <div className={styles.dropdownContent}>
                  {Object.values(tokens).map((coin) => (
                    <div
                      className={styles.dropdownItem}
                      onClick={async () => {
                        setIsSelected(coin);
                        setIsActive(false);
                        setIsChanged(true);
                        await getTokenBalance(
                          coin.symbol,
                          addresses[cryptoName][index]
                        );
                      }}
                      key={coin.address}
                    >
                      <img
                        src={coin.image}
                        style={{
                          height: 25,
                          marginRight: 5,
                          height: 40,
                          marginRight: 35,
                        }}
                      />
                      <p
                        style={{
                          fontSize: 20,
                          fontWeight: 700,
                          paddingTop: 10,
                        }}
                      >
                        {coin.symbol}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className={styles.columnConnection}>
              <div className={`${styles.flexDiv} cont`}>
                <p className={styles.pStyle}>{t("OperationCard.sendToken2")}</p>
                <div className={styles.flexDiv}>
                  <div className={styles.flexDiv}>
                    <label
                      htmlFor="destinationAddress"
                      className={styles.label}
                    >
                      {t("TransferCard.destinationAddress")}
                    </label>
                    <input
                      type="text"
                      id="destinationAddress"
                      value={destinationAddress}
                      onChange={handleDestinationAddressChange}
                      className={styles.input}
                      placeholder={t("OperationCard.placeholderAddr")}
                      required
                    />
                    <div className={styles.textMuted}>
                      *{t("TransferCard.validAddress")}
                      <span style={{ color: "#001e66", fontWeight: 700 }}>
                        {cryptoName.toUpperCase()}
                      </span>{" "}
                      {t("TransferCard.valid")}
                    </div>
                  </div>
                  <div className={styles.formGroup}>
                    <div className={styles.flexDiv}>
                      <label
                        htmlFor="amount"
                        className={styles.label}
                        style={{ marginTop: 20 }}
                      >
                        {t("TransferCard.amount")}
                      </label>
                      <input
                        type="text"
                        id="amount"
                        value={amount}
                        onChange={handleAmountChange}
                        placeholder={t("OperationCard.placeholderAmount")}
                        className={styles.input}
                        required
                      />
                      {isChanged && (
                        <div className={styles.textMuted}>
                          *{t("TransferCard.currentAmountToken")}{" "}
                          <span style={{ color: "#001e66", fontWeight: 700 }}>
                            {tokenBalance} {selected.symbol}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <button
                    onClick={()=>handleExchange(selected.symbol)}
                    className={styles.submitButton}
                  >
                    {t("TransferCard.submit")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.containerCard}>
          <div className={`${styles.connectionCardLoading} connecting`}>
            <div className={styles.rowConnection}>
              <div>
                <FiArrowLeft
                  className={styles.firstRowIcon}
                  onClick={returnBackFromToken}
                />
              </div>
              <div>
                <p>{t("OperationCard.cardCryptoTitle")}</p>
              </div>
              <div>
                <AiOutlineClose
                  className={styles.firstRowIcon}
                  onClick={handleViewCard}
                />
              </div>
            </div>
            <div className={styles.columnConnectionLoading}>
              <div className={styles.loaderParent}>
                <p className={styles.pStyle}>
                  {t("TransferCard.confirmation1")}{" "}
                  <span style={{ color: "#001e66", fontWeight: 700 }}>
                  {t("TransferCard.confirmation2")}{" "}
                  </span>
                </p>
                <div className={styles.loader}>
                  <ClipLoader size={40} color="#001e66" />
                  <p style={{ marginLeft: 10, marginTop: 8 }}>Loading...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TokenTransfer;
