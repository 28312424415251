import React from 'react'
import styles from "./styles/SuccessPopUp.module.css";
import CheckLogo from '../check.png'
import { Button } from 'react-bootstrap'

const SuccessPop= ({message,handleSuccess,title,t}) => {
    return (
      <div className={styles.popupBox}>
        <div className={styles.box}>
          <div className={styles.upperInnerBox}>
            <img alt='success' className={styles.checkLogo} src={CheckLogo} />
          </div>
          <div className={styles.bottomInnerBox}>
            <div className={styles.bottomLabel}>
              <h3 style={{color:"black"}}>{title? title: t("SuccessPopUp.titleCard")}</h3>
              <p style={{color:"black"}}>{message}</p>
              <Button className={styles.closeButton} onClick={()=>{
                handleSuccess()
              }}>Chiudi</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

export default SuccessPop