import { AiOutlineClose } from "react-icons/ai";
import {MdContentCopy} from "react-icons/md"
import styles from "../components/styles/OperationCard.module.css";
import { useState } from "react";
import TransferCard from "./TransferCard";

const OperationCard = ({ index,handleViewCard, cryptoName,handleSuccessClick,balances, addressMap,addresses, t }) => {
  const [isCryptoClicked, setIsCryptoClicked] = useState(false);
  const [isTokenClicked, setIsTokenClicked] = useState(false);
  const [copyPopupVisible, setCopyPopupVisible] = useState(false);
  const [copyPopupText, setCopyPopupText] = useState("");
  const [copyPopupPosition, setCopyPopupPosition] = useState({
    top: 0,
    left: 0,
  });

  const handleCopyClick = (text, event) => {
    navigator.clipboard.writeText(text);
    setCopyPopupText(text);

    const mouseX = event.clientX;
    const mouseY = event.clientY;
    setCopyPopupPosition({ top: mouseY, left: mouseX });
    setCopyPopupVisible(true);

    setTimeout(() => {
      setCopyPopupVisible(false);
    }, 2000); // Nascondi il popup dopo 2 secondi (puoi cambiare il valore a tuo piacimento)
  };

  const handleCryptoClick = () => {
    setIsCryptoClicked(!isCryptoClicked);
  };

  const handleTokenClick = () => {
    setIsTokenClicked(!isTokenClicked);
  };

  const handleCardVisualization = () => {
    handleViewCard();
  };
  return (
    <>
      {isCryptoClicked || isTokenClicked ? (
        <TransferCard
          index={index}
          cryptoName={cryptoName}
          isCryptoTransfer={isCryptoClicked}
          isTokenTransfer={isTokenClicked}
          handleViewCard={handleCardVisualization}
          returnBackForCrypto={handleCryptoClick}
          returnBackFromToken={handleTokenClick}
          addresses={addresses}
          handleSuccessClick={handleSuccessClick}
          balances={balances}
        />
      ) : (
        <div className={styles.containerCard}>
          <div className={`${styles.connectionCard} connecting`}>
            <div className={styles.rowConnection}>
              <div>
                <p>{t("OperationCard.card1title")}</p>
              </div>
              <div>
                <AiOutlineClose
                  className={styles.firstRowIcon}
                  onClick={() => handleViewCard()}
                />
                
              </div>
            </div>
            {copyPopupVisible && (
                  <div
                    className={styles.copyPopup}
                    
                  >
                    {t("History.copied")} {copyPopupText}
                  </div>
                )}
            <div className={styles.columnConnection}>
              <div className={styles.flexDivSecond} style={{gap:"5px"}}>
                <p style={{alignSelf: "center",margin:0}}>Address <span className={styles.spanStyle}>{cryptoName}<sup >{index}</sup></span>: <span style={{color:"#001E66",fontWeight:700}}>{addressMap[cryptoName][index].substring(0, 4) +
                "..." +
                addressMap[cryptoName][index].substring(addressMap[cryptoName][index].length - 5)}</span></p> 
                <MdContentCopy onClick={(event)=>handleCopyClick(addressMap[cryptoName][index],event)} className={styles.iconStyle}/>
              </div>
              <div className={`${styles.flexDiv} cont`}>
                <p className={styles.pStyle}>
                  {cryptoName === "ETH" ||
                  cryptoName === "BNB" /*||
                  cryptoName === "SOL" */? (
                    <>
                      {t('OperationCard.chooseSend')}{' '}
                      <span style={{ color: "#001e66",fontWeight:700  }}>
                        {t('OperationCard.cryptoCurrency')}
                      </span>{' '}
                      {t('OperationCard.or')}{' '}
                      <span style={{ color: "#001e66" ,fontWeight:700 }}>
                        {t('OperationCard.token')}
                      </span>
                    </>
                  ) : (
                    <>
                      {t('OperationCard.sendCrypto')}{' '}
                      <span style={{ color: "#001e66",fontWeight:700}}>
                        {t('OperationCard.cryptoCurrency')}
                      </span>{' '}
                      {t('OperationCard.byClicking')}
                    </>
                  )}
                </p>
              </div>

              <div className={styles.flexDivSecond}>
                <div className={styles.flexDiv}>
                  <p style={{ marginBottom: 5 }}>Crypto</p>
                  <div
                    className={styles.secondRowConnection}
                    onClick={handleCryptoClick}
                  >
                    {cryptoName === "BTC" && (
                      <img
                        src="/vector3.svg"
                        style={{ width: 50 }}
                        className={styles.cryptoImage}
                      />
                    )}
                    {cryptoName === "ETH" && (
                      <img
                        src="/vector4.svg"
                        style={{ width: 50 }}
                        className={styles.cryptoImage}
                      />
                    )}
                    {cryptoName === "BNB" && (
                      <img
                        src="/vector5.svg"
                        style={{ width: 65 }}
                        className={styles.cryptoImage}
                      />
                    )}
                    {cryptoName === "SOL" && (
                      <img
                        src="/solana.svg"
                        style={{ width: 55 }}
                        className={styles.cryptoImage}
                      />
                    )}
                    {cryptoName === "XMR" && (
                      <img
                        src="/monero.svg"
                        style={{ width: 65 }}
                        className={styles.cryptoImage}
                      />
                    )}
                  </div>
                </div>
                {cryptoName === "ETH" ||
                cryptoName === "BNB" /*||
                cryptoName === "SOL" */? (
                  <div className={styles.flexDiv} style={{ alignItems: "center" }}>
                    <p style={{ marginBottom: 5 }}>Token</p>
                    <div
                      className={`${styles.secondRowConnection} alt`}
                      onClick={handleTokenClick}
                    >
                      <img
                        src="/tokenIcon.png"
                        style={{ width: 75 }}
                        className={styles.cryptoImage}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OperationCard;
