import { useTranslation } from "react-i18next";
import styles from "./styles/DropExchange1.module.css";
import { ClipLoader } from "react-spinners";
const DropExchange1 = ({ handleViewCard, generateAddress, loading }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.dropExchange1}>
      {loading ? (
        <>
          <ClipLoader size={40} color="#c8b568" />
          <p style={{ marginLeft: 10, marginTop: 8 }}>Loading...</p>
        </>
      ) : (
        <>
          <p style={{ textAlign: "center" }}>{t("DropExchange.title")}</p>
          <div
            className={styles.btc}
            onClick={async () => {
              await generateAddress("BTC");
            }}
          >
            BTC
          </div>
          <div
            className={styles.btc}
            onClick={async () => {
              await generateAddress("ETH");
            }}
          >
            ETH
          </div>
          <div
            className={styles.btc}
            onClick={async () => {
              await generateAddress("BNB");
            }}
          >
            BNB
          </div>
          <div
            className={styles.btc}
            onClick={async () => {
              await generateAddress("SOL");
            }}
          >
            SOL
          </div>
          <div
            className={styles.btc}
            onClick={async () => {
              await generateAddress("XMR");
            }}
          >
            XMR
          </div>
          <p style={{ fontSize: 10 }}>*{t("DropExchange.paragraph")}</p>
        </>
      )}
    </div>
  );
};

export default DropExchange1;
