import { useCallback } from "react";
import stylesClient from "./styles/SupportoContainer.module.css";
const SupportoContainer = ({t}) => {
  const onButtonContainer1Click = useCallback(() => {
    window.open("google.com");
  }, []);

  return (
    <div className={stylesClient.item}>
      <div className={stylesClient.base} />
      <div className={stylesClient.support}>{t(`Support.supporto`)}</div>
      <img className={stylesClient.icon} alt="" src="/icon1.svg" />
      <div className={stylesClient.button} onClick={onButtonContainer1Click}>
        <div className={stylesClient.background}>
          <div className={stylesClient.shadow} />
          <div className={stylesClient.fill} />
        </div>
        <div className={stylesClient.content}>
          <b className={stylesClient.label}>{t(`Support.servizioClienti`)}</b>
        </div>
      </div>
      <div className={stylesClient.checkOutHelpful}>
      {t(`Support.checkOutHelpful`)}
      </div>
    </div>
  );
};

export default SupportoContainer;
