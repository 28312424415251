import React, { useState } from "react";
import styles from "../components/styles/ToggleSwitch.module.css";
import { use2FA } from "../context/TwoFAProvider";
import { useCookies } from "react-cookie";

const ToggleSwitch = ({t}) => {
  const {handleSetToggleActive} = use2FA();
  const [cookies, setCookie] = useCookies(["2FA"]);
  return (
    <div className={styles.switch_container}>
      <button
        className={`${styles.switch_button} ${cookies["2FA"]=="true" ? styles.active : ''}`}
        onClick={handleSetToggleActive}
      >
        <div className={`${styles.slider} ${styles.round}`}></div>
      </button>
      <span className={styles.switch_label}>
        {cookies["2FA"]=="true" ? t("Settings.2FAactive") : t("Settings.2FADisactive")}
      </span>
    </div>
  );
};

export default ToggleSwitch;
