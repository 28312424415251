import React from "react";
import styles from "../components/styles/Disactivate2FACard.module.css"
import { AiOutlineClose } from "react-icons/ai";
import { use2FA } from "../context/TwoFAProvider";
import SuccessPop from "./SuccessPop";
import { useState } from "react";
import FailurePopUp from "./FailurePopUp";
import axios from "axios";
import { useAuth } from "../context/AuthProvider";
import { useContext } from "react";
import { BaseUrlContext } from "../context/BaseUrlContext";
const Disactivate2FACard = ({t}) => {
    
  const {toggle2FA,handleSetToggleActive} = use2FA();
  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const { token } = useAuth();
  const { baseUrl } = useContext(BaseUrlContext);

  const DISABLE_2FA_URL="/api/disable2FA"

  const handleFailure = (e) => {
    setErrMsg("")
  };

  const handle2FAdeactivation = async () => {
    try {
      const response = await axios.post(
        baseUrl + DISABLE_2FA_URL,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      setSuccess(true)
    }catch(error){
      console.log(error)
      setErrMsg(error.response.data.message)
    }
  }

  const handleDisactive2FA = async () => {
    setSuccess(!success);
    handleSetToggleActive();
    setTimeout(() => {
      toggle2FA(false);
    }, 500);
  };
     return(
        <>
        {success && (
        <SuccessPop
          title={t("Disactivate2FA.success")}
          message={t("Disactivate2FA.successSubTitle")}
          handleSuccess={handleDisactive2FA}
          t={t}
        />
        )}
        {errMsg && (
        <FailurePopUp
        message={errMsg}
        handleFailure={handleFailure}
        t={t}
        />
        )}
            <div className={styles.containerCard}>
                <div className={styles.connectionCard}>
                    <div className={styles.rowConnection}>
                        <div>
                        <h1>{t("Disactivate2FA.title")}</h1>
                        </div>
                        <div>
                        <AiOutlineClose
                            className={styles.firstRowIcon}
                            onClick={handleSetToggleActive}
                        />
                        </div>
                    </div>
                <div className={styles.columnConnection}>
                    <p style={{fontSize:18,textAlign:"center"}}>{t("Disactivate2FA.subtitle")}</p>
                    <p style={{fontSize:12,textAlign:"left"}}>{t("Disactivate2FA.subsubtitle")}</p>

                </div>
                <div className={styles.flexDiv}>
                    <button className={styles.submitButton2} onClick={handleSetToggleActive}>{t("Disactivate2FA.button2")}</button>
                    <button className={styles.submitButton} onClick={()=>handle2FAdeactivation()}>{t("Disactivate2FA.button1")}</button>
                </div>
                
            </div>
            </div>
        </>
     )
};

export default Disactivate2FACard