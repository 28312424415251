import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState, useCallback, useContext, useRef } from "react";
import styles from "./pages/styles/Dashboard.module.css";
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import Verify from "./pages/Verify";
import VerifyTokenChangePassword from "./pages/VerifyTokenChangePassword";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import OperationCard from "./components/OperationCard";
import { useAuth } from "./context/AuthProvider";
import { BaseUrlContext } from "./context/BaseUrlContext";
import axios from "axios";
import Dashboard from "./pages/Dashboard";
import Impostazioni from "./pages/Impostazioni";
import Supporto from "./pages/Supporto";
import Storia from "./pages/Storia";
import {SidebarLeft} from "./components/SidebarLeft";
import SidebarRight from "./components/SidebarRight";
import RecoverPassword from "./components/RecoverPassword";
import ChangeDevice from "./components/ChangeDevice";
import RestoreKeysPage from "./components/RestoreKeysPage";
import InfoCard from "./components/InfoCard";

function App() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 1205);
  const [cookies, setCookie] = useCookies(["language"]);
  const [cookies2FA, setCookie2FA] = useCookies(["2FA"]);
  const [isActive, setIsActive] = useState(false);
  const [isInfoActive, setIsInfoActive] = useState(false);
  const { token, setAuthToken } = useAuth();
  const [addresses, setAddresses] = useState({});
  const [balances, setBalances] = useState("");
  const [currentAddress,setCurrentAddress] =useState("")
  
  const [latestTransactions,setLatestTransactions] =useState([]);
  
  const [singleBalance, setSingleBalance] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [success, setSuccess] = useState(false);
  const { baseUrl } = useContext(BaseUrlContext);

  /* API */
  const ADDRESSES_URL = "/api/getAddresses";
  const BALANCES_URL = "/api/getAllBalances";
  const SINGLE_BALANCES_URL = "/api/getBalance"
  const LATEST_TRANSACTION_URL="/api/getTransactions"
  
  const [currentAccount, setCurrentAccount] = useState("");
  const [isDropmenuOpen, setDropmenuOpen] = useState(false);
  const [coinType, setCoinType] = useState("BTC");
  const [currentCoinType, setCurrentCoinType] = useState("");
  const [balanceLoading, setBalanceLoading] = useState(false)
  const [singleBalanceLoading, setSingleBalanceLoading] = useState({
    loading: false,
    cryptoName: "BTC",
  });
  const [addressesLoading, setAddressesLoading] = useState(false)
  const [dropmenuClick, setDropmenuClick] = useState(false)
  const [successClick,setSuccessClick]= useState(false)
  const [addAccountClick,setAddAccountClick]= useState(false)
  const [addressMap, setAddressMap] = useState({});
  const [index, setIndex] = useState(0);
  const [operation,setOperation] =useState(false)
  const [parsedBalance,setParsedBalance] =useState()
  const [userLoggedIn, setUserLoggedIn] = useState(true); 

  /* Paths */
  const storiaPath=t("Paths./history")
  const supportoPath=t("Paths./support")
  const impostazioniPath=t("Paths./settings")
  const registrationPath=t("Paths./registration")
  const loginPath=t("Paths./login")
  const recoverPath=t("Paths./recover")
  const restorekeysPath=t("Paths./restorekeys")
  const verifyPath=t("Paths./api/verify")
  const verifyTokenChangePasswordPath=t("Paths./api/verifyTokenChangePassword")
  const navigate = useNavigate();

  /* Languages */
  const languages= {
    en: {nativeName:"English"},
    it: {nativeName:"Italian"}
  }

  const sessionTimerRef = useRef(null);
  const resetSessionTimer = () => {
    clearInterval(sessionTimerRef.current);
    const newSessionTimer = setInterval(handleLogout, 5 * 60 * 1000); // 5 minutes
    sessionTimerRef.current = newSessionTimer;
  };

  useEffect(() => {
    const handleUserInteraction = () => {
      resetSessionTimer();
    };

    window.addEventListener("click", handleUserInteraction);
    window.addEventListener("keydown", handleUserInteraction);
    window.addEventListener("scroll", handleUserInteraction);
    
    resetSessionTimer(); 
    
    return () => {
      window.removeEventListener("click", handleUserInteraction);
      window.removeEventListener("keydown", handleUserInteraction);
      clearInterval(sessionTimerRef.current); 
    };
  }, []);

  /*useEffect(() => {
    if (userLoggedIn && (token === "null" || token === null)) {
      //handleLogout();
    }
  }, []);*/

  useEffect(() => {
    if (!cookies2FA["2FA"]) {
      setCookie2FA("2FA",false)
    } 
  }, [])



  const handleLogout = () => {
    setAuthToken(null)
    setUserLoggedIn(false);
    clearInterval(sessionTimerRef.current); 
    navigate("/");
  };

  const handleSetCurrentParsedBalance = (parsedBalance) => {
    setParsedBalance(parsedBalance)
  };

  const handleSuccessClick =()=>{
    setSuccessClick(!successClick)
  }

  const setLoadingTrue = () => {
    setSingleBalanceLoading((prevState) => ({
      ...prevState,
      loading: true,
    }));
  };

  const setLoadingFalse = () => {
    setSingleBalanceLoading({
      loading: false,
    });
  };

  const handleCurrentAccount = useCallback((account, _coinType, index,address) => {
    setCurrentAccount(account);
    setCurrentAddress(address)
    setDropmenuOpen(false);
    setCoinType(_coinType);
    setCurrentIndex(index);
  }, []);

  const setCryptoName = (cryptoName) => {
    setSingleBalanceLoading({
      cryptoName: cryptoName,
    });
  };


  useEffect(() => {
    const savedLanguage = cookies.language;
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    } else {
      const defaultLanguage = "en";
      i18n.changeLanguage(defaultLanguage);
    }
  }, [i18n, cookies.language]);

  const handleLanguageChange = useCallback((language) => {
    i18n.changeLanguage(language);
    setCookie("language", language, { path: "/" , SameSite: 'lax', secure: true});
    navigate(impostazioniPath)
  }, [i18n, setCookie]);

  useEffect(() => {
    const handleResize = () => {
      setIsScreenSmall(window.innerWidth < 1205);
    };
    if(window.innerWidth < 1205){
      setAuthToken(null)
    }
    window.addEventListener('resize', handleResize);

    // Pulisci l'evento al dismount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Funzione per gestire la chiusura della pagina
  useEffect(() => {
    const handleUnload = () => {
      sessionStorage.setItem("pageReloaded", "true");
    };
  
    window.addEventListener("unload", handleUnload);
  
    const isPageReloaded = sessionStorage.getItem("pageReloaded");
  
    if (isPageReloaded === null) {
      handleLogout();
    } else {
      sessionStorage.removeItem("pageReloaded");
    }
  
    return () => {
      window.removeEventListener("unload", handleUnload);
    };
  }, []);
  
  const handleViewCard = async (operation) => {
    setIsActive(!isActive)
    setOperation(operation)
  };

  const handleViewInfoCard = async () => {
    setIsInfoActive(!isInfoActive)
  };

  const handleCurrentCoinType = (cryptoName) => {
    setCurrentCoinType(cryptoName)
  };

  const getAddresses = useCallback(async () => {
    if (token==="null"|| token ===null) return;
    try {
      setAddressesLoading(true)
      const response = await axios.post(
        baseUrl + ADDRESSES_URL,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      setAddressesLoading(false)
      setAddresses(response.data.data);
      let account=addresses["BTC"][0].substring(0, 4) +"..." +addresses["BTC"][0].substring(addresses["BTC"][0].length - 5)
      setCurrentAccount(account);
      setSuccess(true);
    } catch (err) {
      console.log(err);
    }
  }, [baseUrl, token, t]);

  const getBalances = useCallback(async () => {
    if (token==="null"|| token ===null || operation) return;
    try {
      setBalanceLoading(true)
      const response = await axios.post(
        baseUrl + BALANCES_URL,
        {
          network: process.env.REACT_APP_NETWORK_TYPE
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      setBalanceLoading(false)
      const balancesArray = Object.entries(response.data.data);
      let formattedBalances = {};
      for (const [coin, balances] of balancesArray) {
        formattedBalances[coin] = balances.map((balance, index) => ({
          index: index,
          balance: balance.balance,
        }));
      }
      
      formattedBalances=JSON.stringify(formattedBalances)
      setBalances(formattedBalances);
    } catch (err) {
      console.log(err)
    }
  }, [ token, t]);

  const getSingleBalance = useCallback(async () => {
    if (token==="null" || token ===null || !operation) return;
    try {
      setCryptoName(coin)
      setLoadingTrue()
      const response = await axios.post(
        baseUrl + SINGLE_BALANCES_URL,
        {
          cointype:coin,
          address:address,
          network:process.env.REACT_APP_NETWORK_TYPE
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      setSingleBalance(response.data.data);
      
      setLoadingFalse()
    } catch (err) {
      console.log(err)
    }
  }, [token, t]);

  
  const getLatestTransactions = useCallback(async () => {
    if (token==="null" || token ===null) return;
    try {
      const response = await axios.post(
        baseUrl + LATEST_TRANSACTION_URL,
        {
          limit:5,
          offset:0
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      setLatestTransactions(response.data.data.transactions)
      console.log(response.data.data)
    } catch (err) {
      console.log(err)
    }
  }, [baseUrl, token, t]);

  useEffect(() => {
    const fetchData = async () => {
      await getLatestTransactions();
    };
    fetchData();
  }, [getLatestTransactions]);

  useEffect(() => {
    const fetchData = async () => {
        await getBalances();
    };
    fetchData();
  }, [getBalances,successClick]);

  useEffect(() => {
    const fetchData = async () => {
        await getSingleBalance();
    };
    fetchData();
  }, [getSingleBalance,successClick]);

  useEffect(() => {
    const fetchData = async () => {
        await getAddresses();
    };
    fetchData();
  }, [getAddresses,addAccountClick]);

  const handleClickDropmenu = () => {
     setDropmenuClick(!dropmenuClick)
  };

  const handleClickAddAccount = () => {
    setAddAccountClick(!addAccountClick)
 };

  const handleIndex = (index) =>{
    setIndex(index)
  }

  const handleAddressMap = (index, cryptoName, address) => {
    setAddressMap((prevAddressMap) => ({
      ...prevAddressMap,
      [cryptoName]: {
        ...prevAddressMap[cryptoName],
        [index]: address,
      },
    }));
  };


  // useEffect che monitora le modifiche a 'addresses'
  useEffect(() => {
    if (addresses && addresses["BTC"] && addresses["BTC"][0]) {
        let account = addresses["BTC"][0].substring(0, 4) + "..." + addresses["BTC"][0].substring(addresses["BTC"][0].length - 5);
        setCurrentAccount(account);
    }
  }, [addresses]);

  const sharedPropsDropmenu= {
    handleClickDropmenu,
    handleClickAddAccount,
    handleSuccessClick,
    handleCurrentAccount,
    setDropmenuOpen,
    isDropmenuOpen,
    addresses
  }

  const isRegistrationPath = location.pathname === registrationPath;
  const isLoginPath = location.pathname === loginPath;
  const isRecoverPath = location.pathname === recoverPath;
  const isRestoreKeysPath = location.pathname === restorekeysPath;
  const isVerifyPath = location.pathname === verifyPath;
  const isVerifyTokenChangePasswordPath = location.pathname === verifyTokenChangePasswordPath;
  const isTokenNull = token === "null" || token === null;
  const tokenParam = new URLSearchParams(window.location.search).get("token");
  const confirmParam = new URLSearchParams(window.location.search).get("confirm");
  const activationParam = new URLSearchParams(window.location.search).get("activation");
  
  return (
   <>
   {isScreenSmall ?
      <ChangeDevice t={t}/> :    
    !addressesLoading ?
    <>
      {isActive && <OperationCard index={index} balances={balances} addressMap={addressMap} handleSuccessClick={handleSuccessClick} handleViewCard={handleViewCard} cryptoName={currentCoinType} t={t} addresses={addresses} />}
      {isInfoActive && <InfoCard index={index} addressMap={addressMap} parsedBalance={parsedBalance} handleViewInfoCard={handleViewInfoCard} cryptoName={currentCoinType} t={t} addresses={addresses} />}
      
	  {(isRegistrationPath) ? (
        <Registration t={t} />
      ) : (isTokenNull && isLoginPath) ? (
        <Login t={t} confirmParam={confirmParam} activationParam={activationParam} />
      ) : (isTokenNull && isRecoverPath && tokenParam) ? (
        <RecoverPassword t={t} />
      ) : (isTokenNull && isRestoreKeysPath) ? (
        <RestoreKeysPage t={t} />
      ) : (isTokenNull && isVerifyPath && tokenParam) ? (
        <Verify t={t} />
      ) : (isTokenNull && isVerifyTokenChangePasswordPath && tokenParam) ? (
        <VerifyTokenChangePassword t={t} />
      ) : (!isTokenNull) ? (
        <div className={styles.dashboard}>
          <SidebarLeft t={t} handleLogout={handleLogout} />
          <img className={styles.dashboardChild} alt="" src="/line-7.svg" />
          <Routes>
            <Route path="/" element={<Dashboard t={t} handleIndex={handleIndex} handleAddressMap={handleAddressMap} handleSetCurrentParsedBalance={handleSetCurrentParsedBalance} addresses={addresses} singleBalanceLoading={singleBalanceLoading} loading={balanceLoading} handleViewCard={handleViewCard} handleViewInfoCard={handleViewInfoCard} handleCurrentCoinType={handleCurrentCoinType} currentCoinType={currentCoinType} coinType={coinType} balances={balances} currentIndex={currentIndex} singleBalance={singleBalance}/>} />
            <Route path={impostazioniPath} element={<Impostazioni i18n={i18n} t={t} languages={languages} handleLanguageChange={handleLanguageChange} />} />
            <Route path={supportoPath} element={<Supporto t={t} />} />
            <Route path={storiaPath} element={<Storia t={t} currentAddress={currentAddress} dropmenuClick={dropmenuClick} sharedPropsDropmenu={sharedPropsDropmenu} cryptoName={coinType}/>} />
          </Routes>
          
          {currentAccount &&
          <SidebarRight
            t={t}
            balances={balances}
            addresses={addresses}
            cryptoName={coinType}
            setDropmenuOpen={setDropmenuOpen}
            currentIndex={currentIndex}
            isDropmenuOpen={isDropmenuOpen}
            handleCurrentAccount={handleCurrentAccount}
            currentAccount={currentAccount?currentAccount:null}
            loading={balanceLoading}
            handleClickDropmenu={handleClickDropmenu}
            handleClickAddAccount={handleClickAddAccount}
            handleSuccessClick={handleSuccessClick}
            singleBalance={singleBalance}
            latestTransactions={latestTransactions}
          />}
        </div>
      ) : <Login t={t} /> }
    </>:null}
    </>
  );
}

export default App;
