import React from "react";
import styles from "../components/styles/GoogleAuthCard.module.css";
import { FiArrowLeft } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { use2FA } from "../context/TwoFAProvider";
import { useEffect } from "react";
import { useState } from "react";
import SuccessPop from "./SuccessPop";
import { useContext } from "react";
import { BaseUrlContext } from "../context/BaseUrlContext";
import { useAuth } from "../context/AuthProvider";
import axios from "axios";
import FailurePopUp from "./FailurePopUp";

const GoogleAuthCard = ({ t }) => {
  const {
    handleSetToggleActive,
    toDataURL,
    toggle2FA
  } = use2FA();

  const [verifyError, setVerifyError] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [success, setSuccess] = useState(false);
  const [qrcode, setQrcode] = useState();
  const [secret, setSecret] = useState();

  const { token } = useAuth();
  const { baseUrl } = useContext(BaseUrlContext);
  const ENABLE_2FA_URL="/api/enable2FA"
  const GENERATE_TOTP_URL="/api/generateTotp"
  const VERIFY_OTP_URL="/api/verifyOTP"

  const handleAuthCode = (event) => {
    setVerifyError("")
    let value = event.target.value;
    const regex = /^[0-9]{0,6}$/;
    if (regex.test(value)) {
      setAuthCode(value);
    }
  };

  const handleVerifyToken = async () => {
    try {
      const response = await axios.post(
        baseUrl + VERIFY_OTP_URL,
        {
          otp: authCode
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      console.log(response)
      if(response.data){
        setSuccess(true)
      }
      

    }catch(error){
      console.log(error)
      setVerifyError(error.response.data.message)
    }
  }

  const handle2FAactivation = async () => {
    try {
      const response = await axios.post(
        baseUrl + ENABLE_2FA_URL,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      setTimeout(() => {
        toggle2FA(true);
      }, 500);
    }catch(error){
      console.log(error)
      setVerifyError(error.response.data.message)
    }
  }

  

  const generateTOTP = async (e) => {
    try {
      const response = await axios.post(
        baseUrl + GENERATE_TOTP_URL,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      console.log(response)
      if(response.data){
        let qrcodeData=await toDataURL(response.data.data.qrCodeUrl)
        setQrcode(qrcodeData)
        setSecret(response.data.data.totp)
      }
      

    }catch(error){
      console.log(error)
      setErrMsg(error.response.data.message)
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await generateTOTP();
    };
    fetchData();
  }, []);

  const handleSuccess = async () => {
    setSuccess(!success);
    handleSetToggleActive();
    await handle2FAactivation()
  };

  const handleFailure = (e) => {
    setErrMsg("")
  };

  return (
    <>
      {success && (
        <SuccessPop
          title={t("GoogleAuth.success")}
          message={t("GoogleAuth.successSubTitle")}
          handleSuccess={handleSuccess}
          t={t}
        />
      )}

      {errMsg && (
        <FailurePopUp
        message={errMsg}
        handleFailure={handleFailure}
        t={t}
        />
      )}
      <div className={styles.containerCard}>
        <div className={styles.connectionCard}>
          <div className={styles.rowConnection}>
            <div>
              <FiArrowLeft
                className={styles.firstRowIcon}
                onClick={handleSetToggleActive}
              />
            </div>
            <div>
              <p>{t("Settings.activate2FAtitle1")}</p>
            </div>
            <div>
              <AiOutlineClose
                className={styles.firstRowIcon}
                onClick={handleSetToggleActive}
              />
            </div>
          </div>
          <div className={styles.columnConnection}>
            <h1>{t("GoogleAuth.listTitle")}</h1>
            <hr style={{ margin: 5 }} />
            <p>
              <strong>1.</strong> {t("GoogleAuth.list1")}
            </p>
            <p>
              <strong>2.</strong> {t("GoogleAuth.list2")}
            </p>
            <p>
              <strong>3.</strong> {t("GoogleAuth.list3")}
            </p>
          </div>

          <div className={styles.columnConnection}>
            <h1>{t("GoogleAuth.title2")}</h1>
            <hr style={{ margin: 5, width: 400 }} />
            <img src={qrcode} className={styles.imgStyle} />
          </div>

          <div className={styles.columnConnection}>
            <h1>{t("GoogleAuth.title3")}</h1>
            <hr style={{ margin: 5, width: 400 }} />
            <p>SecretKey: {secret}</p>
          </div>

          <div className={styles.columnConnection}>
            <h1>{t("GoogleAuth.title4")}</h1>
            <hr style={{ margin: 5, width: 400 }} />
            <p>{t("GoogleAuth.subtitle4")}</p>
            <input
              type="text"
              id="code"
              value={authCode}
              onChange={handleAuthCode}
              placeholder={t("GoogleAuth.placeholder")}
              className={styles.input}
              required
            />
            <hr style={{ margin: 5, width: 400 }} />
            <div className={styles.row}>
            <button
              onClick={() => handleVerifyToken()}
              className={styles.submitButton}
            >
              {t("GoogleAuth.button")}
            </button>
            {verifyError && (
              <p style={{ color: "red", margin: 0 }}>{verifyError}</p>
            )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoogleAuthCard;
