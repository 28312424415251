import React, { useState, useEffect } from 'react';
import styles from './styles/Carousel.module.css';
import { Transactions } from './Transactions';
import { PortfolioCoins } from './PorfolioCoins';

const Carousel = ({ items, delay = 4000,flag,loading,addresses, t }) => {
  const [index, setIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, delay);

    return () => clearInterval(interval);
  }, [items, delay]);



  const visibleItems = [
    ...items.slice(index, index + 3),
    ...items.slice(0, Math.max(0, 3 - (items.length - index))),
  ];

  return (
    <>
      <div className={styles.carousel} >
        {visibleItems.map((item, i) => (
          <>
              {flag=="Transactions"?
                  <Transactions transaction={item} index={i} addresses={addresses} t={t}/>:
              <PortfolioCoins portfolioCoins={item} index ={i} loading={loading}/>
              }
          </>
        ))}
      </div>
    </>
  );
};

export default Carousel;
