import { useState, useCallback, useEffect, useContext } from "react";
import styles from "./styles/Storia.module.css";
import { Row, Col, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useAuth } from "../context/AuthProvider";
import { BaseUrlContext } from "../context/BaseUrlContext";
import { ClipLoader } from "react-spinners";
import { FaArrowRight } from "react-icons/fa";
import { GoSingleSelect } from "react-icons/go";
import PortalPopup from "../components/PortalPopup";
import Dropmenu from "../components/Dropmenu";

const Storia = ({ t, currentAddress, cryptoName, sharedPropsDropmenu }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [tooltipText, setTooltipText] = useState("");
  const [transactionsPerPage] = useState(20);
  const { token } = useAuth();
  const tableRowClassName = isHovered ? styles.hovered : "";
  const [copiedText, setCopiedText] = useState("");
  const [showCopyPopup, setShowCopyPopup] = useState(false);
  const [copyPopupVisible, setCopyPopupVisible] = useState(false);
  const [copyPopupText, setCopyPopupText] = useState("");
  const [copyPopupPosition, setCopyPopupPosition] = useState({
    top: 0,
    left: 0,
  });

  const { baseUrl } = useContext(BaseUrlContext);
  const TRANSACTION_URL = "/api/getTransactionsByAddress";
  const [showTooltip, setShowTooltip] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [currentPage, setCurrentPage] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [maxPage, setMaxPage] = useState(0);

  const openDropmenu = useCallback(() => {
    sharedPropsDropmenu.setDropmenuOpen(true);
  }, []);

  const closeDropmenu = useCallback(() => {
    sharedPropsDropmenu.setDropmenuOpen(false);
  }, []);

  const handleMouseOver = useCallback((text) => {
    setIsHovered(true);
    setTooltipText(text);
    setCopiedText(text);
  }, []);

  const handleNavigationExplorer = (tx) => {
    if (cryptoName) {
      let explorerLink = "";
  
      switch (cryptoName) {
        case "BTC":
          explorerLink =
            process.env.REACT_APP_NETWORK_TYPE === "TESTNET"
              ? process.env.REACT_APP_BTC_EXPLORER_TESTNET
              : process.env.REACT_APP_BTC_EXPLORER_MAINNET;
          break;
        case "ETH":
          explorerLink =
            process.env.REACT_APP_NETWORK_TYPE === "TESTNET"
              ? process.env.REACT_APP_ETH_EXPLORER_TESTNET
              : process.env.REACT_APP_ETH_EXPLORER_MAINNET;
          break;
        case "BNB":
          explorerLink =
            process.env.REACT_APP_NETWORK_TYPE === "TESTNET"
              ? process.env.REACT_APP_BNB_EXPLORER_TESTNET
              : process.env.REACT_APP_BNB_EXPLORER_MAINNET;
          break;
        case "SOL":
          explorerLink = process.env.REACT_APP_SOL_EXPLORER
          break;
        case "XMR":
          explorerLink =
            process.env.REACT_APP_NETWORK_TYPE === "TESTNET"
              ? null
              : process.env.REACT_APP_XMR_EXPLORER_MAINNET;
          break;
        default:
          console.error("Crypto non supportata");
          return;
      }
  
      if(cryptoName=="SOL"){
        process.env.REACT_APP_NETWORK_TYPE === "TESTNET" &&
        window.open(explorerLink + "tx/" + tx + "?cluster=testnet", "_blank");
        return
      }
      window.open(explorerLink + "tx/" + tx, "_blank");
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleCopyClick = (text, event, type) => {
    navigator.clipboard.writeText(text);
    setCopyPopupText(text);

    const mouseX = event.clientX;
    const mouseY = event.clientY;
    setCopyPopupPosition({ top: mouseY, left: mouseX });
    setCopyPopupVisible(true);

    setTimeout(() => {
      setCopyPopupVisible(false);
      if(type=="tx"){
        handleNavigationExplorer(text)
      }
    }, 2000);
  };

  const handleMouseOut = useCallback(() => {
    setIsHovered(false);
    setTooltipText("");
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await getTransactionsByAddress(currentPage);
    };
    fetchData();
  }, [currentPage, currentAddress]);

  const getTransactionsByAddress = useCallback(
    async (page) => {
      try {
        setLoading(true);
        const response = await axios.post(
          baseUrl + TRANSACTION_URL,
          {
            address: currentAddress,
            limit: transactionsPerPage,
            offset: page * transactionsPerPage,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        setTransactions(response.data.data.transactions);
        setMaxPage(
          Math.ceil(response.data.data.totalCount / transactionsPerPage) - 1
        );
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    },
    [currentAddress]
  );

  const changePage = (direction) => {
    if (direction === "next" && currentPage < maxPage) {
      setCurrentPage(currentPage + 1);
    } else if (direction === "previous" && currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
    setTooltipText("");
    setCopiedText(""); // Reimposta il testo da copiare
  }, []);

  const handleMouseMove = (e) => {
    setTooltipPosition({ top: e.clientY + 10, left: e.clientX + 10 });
  };

  return (
    <>
      <Helmet>
        <title>{t("History.metaTitle")}</title>
        <meta name="description" content={t("History.metaDescription")} />
      </Helmet>
      {loading ? (
        <div>
          <ClipLoader size={40} color="#001e66" />
        </div>
      ) : (
        <>
          {!currentAddress ? (
            <div className={styles.divNoTx}>
              <p className={styles.pStyle}>
                {t("History.selectAddr")} {"  "}
              </p>
              <FaArrowRight size={25} />
              <GoSingleSelect
                onClick={() => openDropmenu()}
                size={40}
                style={{ cursor: "pointer", color: "#c8b568" }}
              />
            </div>
          ) : transactions.length == 0 ? (
            <div className={styles.divNoTx}>
              <p className={styles.pStyle}>
                {t("History.noTxFound")}
                <b className={styles.coinType}>
                  {" "}
                  {currentAddress.substring(0, 4) +
                    "..." +
                    currentAddress.substring(currentAddress.length - 5)}
                </b>
              </p>
              <GoSingleSelect
                onClick={() => openDropmenu()}
                size={40}
                style={{ cursor: "pointer", color: "#c8b568" }}
              />
            </div>
          ) : (
            <div
              className={styles.table_container}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onMouseMove={handleMouseMove}
            >
              <p style={{ textAlign: "center" }}>{t("History.txList")}</p>
              <table>
                <thead>
                  <tr>
                    <th
                      style={{ color: "var(--color-darkgoldenrod)" }}
                      onMouseEnter={() =>
                        handleMouseOver(t("History.idTransazione"))
                      }
                      onMouseLeave={handleMouseOut}
                    >
                      TxID
                    </th>
                    <th
                      onMouseEnter={() =>
                        handleMouseOver(t("History.fromAddress"))
                      }
                      onMouseLeave={handleMouseOut}
                    >
                      {t("History.fromAddress")}
                    </th>
                    <th
                      onMouseEnter={() =>
                        handleMouseOver(t("History.destinationAddress"))
                      }
                      onMouseLeave={handleMouseOut}
                    >
                      {t("History.destinationAddress")}
                    </th>

                    <th
                      style={{ color: "var(--color-darkgoldenrod)" }}
                      onMouseEnter={() => handleMouseOver(t("History.valuta"))}
                      onMouseLeave={handleMouseOut}
                    >
                      {t("History.valuta")}
                    </th>
                    <th
                      onMouseEnter={() =>
                        handleMouseOver(t("Amount in Crypto"))
                      }
                      onMouseLeave={handleMouseOut}
                    >
                      {t("History.importo")}
                    </th>
                    <th
                      onMouseEnter={() => handleMouseOver(t("History.data"))}
                      onMouseLeave={handleMouseOut}
                    >
                      {t("History.data")}
                    </th>
                  </tr>
                </thead>
                {copyPopupVisible && (
                  <div
                    className={styles.copyPopup}
                    style={{
                      top: copyPopupPosition.top,
                      left: copyPopupPosition.left,
                    }}
                  >
                    {t("History.copied")} {copyPopupText}
                  </div>
                )}
                <tbody>
                  {transactions.map((item, i) => (
                    <tr key={i} className={tableRowClassName}>
                      <td
                        onMouseOver={() => handleMouseOver(item.tx)}
                        onMouseOut={handleMouseOut}
                        onClick={(event) => handleCopyClick(item.tx, event, "tx")}
                        style={{ cursor: "pointer" }}
                      >
                        {item.tx}
                      </td>
                      <td
                        onMouseOver={() => handleMouseOver(item.from_address)}
                        onMouseOut={handleMouseOut}
                        onClick={(event) =>
                          handleCopyClick(item.from_address, event)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {item.from_address}
                      </td>
                      <td
                        onMouseOver={() =>
                          handleMouseOver(item.destination_address)
                        }
                        onMouseOut={handleMouseOut}
                        onClick={(event) =>
                          handleCopyClick(item.destination_address, event)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {item.destination_address}
                      </td>
                      {item.chain === item.token ? (
                        <td
                          onMouseOver={() => handleMouseOver(item.chain)}
                          onMouseOut={handleMouseOut}
                        >
                          {item.chain}
                        </td>
                      ) : (
                        <td
                          onMouseOver={() => handleMouseOver(item.token)}
                          onMouseOut={handleMouseOut}
                        >
                          {item.token}
                        </td>
                      )}
                      <td
                        onMouseOver={() => handleMouseOver(item.amount)}
                        onMouseOut={handleMouseOut}
                      >
                        {item.amount}
                      </td>
                      <td
                        onMouseOver={() => handleMouseOver(item.data_insert)}
                        onMouseOut={handleMouseOut}
                      >
                        {formatDate(item.data_insert)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Row>
                <Col xs={6} md={6} lg={6} xl={6}>
                  <Button
                    onClick={() => changePage("previous")}
                    disabled={currentPage === 0}
                  >
                    {t("History.next")}
                  </Button>
                </Col>
                <Col xs={6} md={6} lg={6} xl={6} className="text-right">
                  <Button
                    onClick={() => changePage("next")}
                    disabled={currentPage === maxPage}
                  >
                    {" "}
                    {t("History.prev")}
                  </Button>
                </Col>
              </Row>
              {showTooltip && (
                <div
                  className={styles.tooltip}
                  style={{
                    top: tooltipPosition.top,
                    left: tooltipPosition.left,
                  }}
                >
                  {tooltipText}
                </div>
              )}
            </div>
          )}
        </>
      )}
      {sharedPropsDropmenu.isDropmenuOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeDropmenu}
        >
          <Dropmenu
            t={t}
            onClose={closeDropmenu}
            handleClickAddAccount={sharedPropsDropmenu.handleClickAddAccount}
            handleCurrentAccount={sharedPropsDropmenu.handleCurrentAccount}
            addresses={sharedPropsDropmenu.addresses}
            handleClickDropmenu={sharedPropsDropmenu.handleClickDropmenu}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default Storia;
