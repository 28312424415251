import styles from "./styles/SidebarRight.module.css";

export const Transactions = ({ transaction, index, addresses, t }) => {
  const addressArray = Object.values(addresses[transaction.chain]);
  const isSentStatus=addressArray.includes(transaction.from_address)
  return (
    <>
    {transaction?
      <div key={index} className={styles.listviewActivity}>
        
        <div className={styles.listviewActivityChild}>
        <img
          className={styles.harukiIcons}
          alt=""
          src={
            isSentStatus
              ? "/sent.svg"
              : "/received.svg"
          }
        />
        </div>
        <div className={styles.ethereumRicevuti}>
          <p className={styles.ethereum1}>{transaction.chain}</p>
          <div className={styles.flexDiv2}>
          <p className={styles.ricevuti}>
            {isSentStatus
              ? t("SidebarRight.sentStatus")
              : t("SidebarRight.receivedStatus")}
          </p>
          <div className={`${isSentStatus ? styles.listviewActivityItem : styles.listviewActivityItem2}`} />
          </div>
        </div>

        <div className={styles.flexDiv}>
          <div className={styles.eth1}>
          {isSentStatus
                ? transaction.amount+" "+transaction.token
                : "+"+transaction.amount+" "+transaction.token
          }
          </div>
          
          
          <div className={styles.addressFrom}>
          {t("SidebarRight.sentBy")+ transaction.from_address.substring(0, 4) +
                "..." +
                transaction.from_address.substring(
                  transaction.from_address.length - 5
                )
            }
          </div>
          <div className={styles.addressTo}>
            {t("SidebarRight.receivedBy")+ transaction.destination_address.substring(0, 4) +
                "..." +
                transaction.destination_address.substring(
                  transaction.destination_address.length - 5
                )
            }
          </div>
          
        </div>
      </div>:
      <p>Nessuna Transazione Effettuata</p>}
    </>
  );
};
