
import styles from "../pages/styles/Supporto.module.css"
const EmailItem = ({t}) => {
     return(
        <div className={styles.item}>
            <div className={styles.base} />
            <div className={styles.emailUs}>Email us</div>
            <img className={styles.icon} alt="" src="/icon.svg" />
            <div className={styles.emailUsForContainer}>
              <p className={styles.inviaciUnaMail} dangerouslySetInnerHTML={{ __html: t(`Support.inviaciUnaMail`).replace(/\n/g, "<br>") }}>
              </p>
            </div>
            <div
              className={styles.hellohelpcentercom}
            >
              info@bankount.com
            </div>
        </div>
     )
};

export default EmailItem