import { useEffect } from "react";
import CryptoContainer from "../components/CryptoContainer";
import styles from "./styles/Dashboard.module.css";
import { Helmet } from "react-helmet";

const Dashboard = ({
  singleBalance,
  handleIndex,
  handleAddressMap,
  addresses,
  loading,
  handleViewCard,
  handleViewInfoCard,
  handleSetCurrentParsedBalance,
  handleCurrentCoinType,
  singleBalanceLoading,
  coinType,
  balances,
  currentIndex,
  t
}) => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const extendedNames = {
    BTC: "BITCOIN",
    ETH: "ETHEREUM",
    BNB: "BINANCE",
    SOL: "SOLANA",
    XMR: "MONERO",
  };

  const shouldApplyGap = Object.entries(addresses).length <= 5;
  
  return (
    <>
    <Helmet>
        <title>{t("Dashboard.metaTitle")} </title>
        <meta name="description" content={t("Dashboard.metaDescription")} />
    </Helmet>
    <div className={shouldApplyGap?styles.coinWalletStyle2:styles.coinWalletStyle}>
        {Object.entries(addresses).map(([cryptoName, addrArray]) => {
          return Object.values(addrArray).map((address, index) => {
            return (
              <CryptoContainer
                key={index}
                singleBalance={singleBalance}
                handleSetCurrentParsedBalance={handleSetCurrentParsedBalance}
                cryptoPrice={`/${cryptoName.toLowerCase()}.svg`}
                cryptoName={cryptoName}
                name={extendedNames[cryptoName]}
                handleIndex={handleIndex}
                index={index}
                address={address}
                currentCryptoName={coinType}
                handleViewCard={handleViewCard}
                handleViewInfoCard={handleViewInfoCard}
                handleCurrentCoinType={handleCurrentCoinType}
                balances={balances}
                handleAddressMap={handleAddressMap}
                currentIndex={currentIndex}
                singleBalanceLoading={singleBalanceLoading}
                loading={loading}
              />
            );
          });
        })}
      </div>
    </>
  );
};

export default Dashboard;
