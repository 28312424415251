import React, { useState } from "react";
import styles from "./styles/SeedPage.module.css";
import { useNavigate } from "react-router-dom";

const SeedPage = ({ seed, t }) => {
    const navigate=useNavigate()
    const [isCopiedSeed, setIsCopiedSeed] = useState(false);

    const handleCopy = (text, setIsCopied) => {
        navigator.clipboard.writeText(text);
        setIsCopied(true);

        setTimeout(() => {
            setIsCopied(false);
        }, 1500);
    };

    const loginNavigate = () => {
         navigate("/login?activation=true")
    };

    return (
        <>
            <div className={styles.specific_page}>
                <img className={styles.logoIcon} alt="" src="/logoYellow.png" />
                <p className={styles.parentpStyle}>{t("SeedPage.title")}</p>
                <div
                    className={styles.parentDiv}
                    onClick={() => handleCopy(seed, setIsCopiedSeed)}
                >
                    <p style={{ margin: 0 }}>{seed}</p>
                </div>
                <p className={styles.parentpStyle2}>{t("SeedPage.subtitle")}</p>
                {isCopiedSeed?
                <p className={styles.parentpStyle3}> COPIED!</p>
                :
                <p
                    className={styles.parentpStyle3}
                    onClick={() =>
                        handleCopy(seed, setIsCopiedSeed)
                    }
                >
                    {t("SeedPage.copyClipboard")}
                </p>}
                <button className={styles.button} onClick={loginNavigate}>{t("SeedPage.button")}</button>
            </div>
        </>
    );
};

export default SeedPage;
