import { AiOutlineClose, AiOutlineLink } from "react-icons/ai";
import { RxArrowRight } from "react-icons/rx";
import { MdContentCopy } from "react-icons/md";
import styles from "../components/styles/InfoCard.module.css";
import { useContext, useEffect, useState } from "react";
import { use2FA } from "../context/TwoFAProvider";
import { useAuth } from "../context/AuthProvider";
import { RiArrowDropDownLine } from "react-icons/ri";
import { BaseUrlContext } from "../context/BaseUrlContext";
import axios from "axios";

const InfoCard = ({
  index,
  handleViewInfoCard,
  parsedBalance,
  cryptoName,
  addressMap,
  addresses,
  t,
}) => {
  const [copyPopupVisible, setCopyPopupVisible] = useState(false);
  const [copyPopupText, setCopyPopupText] = useState("");
  const [qrcode, setQrcode] = useState();
  const [tokens, setTokens] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [currentAddress, setCurrentAddress] = useState();
  const [copyPopupPosition, setCopyPopupPosition] = useState({
    top: 0,
    left: 0,
  });
  const { token } = useAuth();
  const { baseUrl } = useContext(BaseUrlContext);
  const [tokenBalances, setTokenBalances] = useState({});
  const GET_TOKEN_LIST = "/api/getListOfSupportedTokens";

  const GET_TOKEN_BALANCE = "/api/getTokens";
  const { toDataURL } = use2FA();

  const getListOfSupportedTokens = async () => {
    try {
      const response = await axios.post(
        baseUrl + GET_TOKEN_LIST,
        {
          chain: cryptoName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      setTokens(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  // Funzione per recuperare il saldo del token
  const getTokenBalance = async (symbol, address) => {
    try {
      console.log("FATTO");
      const response = await axios.post(
        baseUrl + GET_TOKEN_BALANCE,
        {
          chain: cryptoName,
          symbol: symbol,
          address: address,
          network: process.env.REACT_APP_NETWORK_TYPE,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      let tokenBalance = response.data.data;

      // Aggiorna lo stato dei saldi dei token
      setTokenBalances((prevBalances) => ({
        ...prevBalances,
        [symbol]: tokenBalance,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowTokenList = () => {
    if (!isActive) {
      getListOfSupportedTokens();
      Object.values(tokens).forEach(async (coin) => {
        await getTokenBalance(coin.symbol, addresses[cryptoName][index]);
      });
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const qrcodeData = await toDataURL(addresses[cryptoName][index]);
      await getListOfSupportedTokens(cryptoName);
      setQrcode(qrcodeData);
      setCurrentAddress(addresses[cryptoName][index]);
    }

    fetchData();
  }, []);

  const handleCopyClick = (text, event) => {
    navigator.clipboard.writeText(text);
    setCopyPopupText(text);

    const mouseX = event.clientX;
    const mouseY = event.clientY;
    setCopyPopupPosition({ top: mouseY, left: mouseX });
    setCopyPopupVisible(true);

    setTimeout(() => {
      setCopyPopupVisible(false);
    }, 2000); // Nascondi il popup dopo 2 secondi (puoi cambiare il valore a tuo piacimento)
  };

  const handleNavigationExplorer = () => {
    if (cryptoName) {
      let explorerLink = "";
  
      switch (cryptoName) {
        case "BTC":
          explorerLink =
            process.env.REACT_APP_NETWORK_TYPE === "TESTNET"
              ? process.env.REACT_APP_BTC_EXPLORER_TESTNET
              : process.env.REACT_APP_BTC_EXPLORER_MAINNET;
          break;
        case "ETH":
          explorerLink =
            process.env.REACT_APP_NETWORK_TYPE === "TESTNET"
              ? process.env.REACT_APP_ETH_EXPLORER_TESTNET
              : process.env.REACT_APP_ETH_EXPLORER_MAINNET;
          break;
        case "BNB":
          explorerLink =
            process.env.REACT_APP_NETWORK_TYPE === "TESTNET"
              ? process.env.REACT_APP_BNB_EXPLORER_TESTNET
              : process.env.REACT_APP_BNB_EXPLORER_MAINNET;
          break;
        case "SOL":
          explorerLink = process.env.REACT_APP_SOL_EXPLORER
          break;
        case "XMR":
          explorerLink =
            process.env.REACT_APP_NETWORK_TYPE === "TESTNET"
              ? null
              : process.env.REACT_APP_XMR_EXPLORER_MAINNET;
          break;
        default:
          console.error("Crypto non supportata");
          return;
      }
  
      if(cryptoName=="SOL"){
        process.env.REACT_APP_NETWORK_TYPE === "TESTNET" &&
        window.open(explorerLink + "address/" + currentAddress + "?cluster=testnet", "_blank");
        return
      }
      window.open(explorerLink + "address/" + currentAddress, "_blank");
    }
  };

  return (
    <>
      <div className={styles.containerCard}>
        <div className={`${styles.connectionCard} connecting`}>
          <div className={styles.rowConnection}>
            <div>
              <AiOutlineClose
                className={styles.firstRowIcon}
                onClick={() => handleViewInfoCard()}
              />
            </div>
            <div>
              <p style={{ marginRight: 40 }}>Info</p>
            </div>
          </div>
          {copyPopupVisible && (
            <div className={styles.copyPopup}>
              {t("History.copied")} {copyPopupText}
            </div>
          )}
          <div className={styles.columnConnection}>
            <h1>{t("Settings.addressDetail")}</h1>
            <hr style={{ margin: 5, width: 400 }} />
            <p>{t("Settings.infoQr")}</p>
            <img src={qrcode} className={styles.imgStyle} />
            <p style={{ alignSelf: "center" }}>
              {t("Settings.yourAddress")}{" "}
              <span className={styles.spanStyle}>
                {cryptoName}
                <sup>{index}</sup>
              </span>
            </p>

            <div className={styles.flexDivSecond} style={{ gap: "5px" }}>
              <p
                key={index}
                style={{
                  textAlign: "center",
                  margin: 0,
                  overflowWrap: "anywhere",
                }}
              >
                {currentAddress}
              </p>
              <MdContentCopy
                size={cryptoName == "XMR" ? 35 : ""}
                onClick={(event) =>
                  handleCopyClick(addressMap[cryptoName][index], event)
                }
                className={styles.iconStyle}
              />
            </div>

            <button
              onClick={(event) => handleCopyClick(currentAddress, event)}
              className={styles.submitButton}
            >
              {t("Settings.copy")}
            </button>
          </div>

          <div className={styles.columnConnection}>
            <h1>{t("SidebarRight.totalBalance")}</h1>
            <hr style={{ margin: 5, width: 400 }} />
            <div
              className={styles.flexDivSecond}
              style={{ gap: "5px", justifyContent: "center" }}
            >
              <p style={{ marginBottom: 0 }}>
                <b>
                  {parsedBalance} {cryptoName}
                </b>
              </p>
            </div>
          </div>

          {!(cryptoName=="XMR" && process.env.REACT_APP_NETWORK_TYPE === "TESTNET") &&
          <div className={styles.columnConnection}>
            <h1>Link Explorer</h1>
            <hr style={{ margin: 5, width: 400 }} />
            <div
              className={styles.flexDivSecond}
              style={{ gap: "5px", justifyContent: "flex-start" }}
            >
              <p style={{ marginBottom: 0 }}>
                {t("Settings.viewAddress")} <RxArrowRight size={15} />
              </p>
              <AiOutlineLink
                size={20}
                color="#1f4781"
                style={{ cursor: "pointer", marginTop: 5 }}
                onClick={handleNavigationExplorer}
              />
            </div>
          </div>}

          <div className={styles.columnConnection}>
            <h1>{t("Settings.tokenSupport")}</h1>
            <hr style={{ margin: 5, width: 400 }} />
            {!(cryptoName === "ETH" || cryptoName === "BNB") ? (
              <p>
                {t("Settings.noTokenSupport")} {cryptoName}.
              </p>
            ) : (
              <>
                <div
                  className={styles.dropdownClick}
                  onClick={handleShowTokenList}
                  onBlur={() => setIsActive(false)}
                >
                  <p style={{ color: "black", marginBottom: 5 }}>
                    {isActive
                      ? t("Settings.hideTokenList")
                      : t("Settings.viewTokenList")}
                  </p>
                  <RiArrowDropDownLine size={25} style={{ color: "black" }} />
                </div>
                {isActive && (
                  <div className={styles.dropdownContent}>
                    {Object.values(tokens).map((coin) => (
                      <div className={styles.dropdownItem} key={coin.address}>
                        <img
                          src={coin.image}
                          style={{
                            height: 25,
                            marginRight: 5,
                            height: 40,
                            marginRight: 35,
                          }}
                          alt={`${coin.symbol} Logo`}
                        />
                        <p
                          style={{
                            fontSize: 20,
                            fontWeight: 700,
                            paddingTop: 10,
                            marginRight: 20,
                          }}
                        >
                          {coin.symbol}
                        </p>
                        <p
                          style={{
                            fontSize: 15,
                            fontWeight: 700,
                            marginBottom: 0,
                          }}
                        >
                          Balance: {tokenBalances[coin.symbol] || 0}{" "}
                          {coin.symbol}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoCard;
