import axios from "axios";
import styles from "./styles/Dropmenu.module.css";
import { useAuth } from "../context/AuthProvider";
import { useContext, useState } from "react";
import { BaseUrlContext } from "../context/BaseUrlContext";
import DropExchange1 from "./DropExchange1";
import SuccessPop from "./SuccessPop";
import FailurePopUp from "./FailurePopUp";
const Dropmenu = ({
  handleCurrentAccount,
  handleClickAddAccount,
  handleClickDropmenu,
  addresses,
  t
}) => {
  const { baseUrl } = useContext(BaseUrlContext);
  const { token } = useAuth();
  const [viewCard, setViewCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const NEW_ADDRESS_URL = "/api/addCryptoToAccount";

  const handleViewCard = () => {
    handleClickAddAccount();
    setViewCard(!viewCard);
    console.log(viewCard);
  };

  const handleGeneration = async (cryptoName) => {
    await generateAddress(cryptoName);
  };

  const generateAddress = async (cryptoName) => {
    try {
      setLoading(true);
      await axios.post(
        baseUrl + NEW_ADDRESS_URL,
        {
          cointype: cryptoName,
          network: process.env.REACT_APP_NETWORK_TYPE,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      setLoading(false);
      setSuccess(true);
      setViewCard(!viewCard);
    } catch (err) {
      console.log(err)
      setLoading(false)
      setViewCard(!viewCard);
      setSuccess(false);
      setFailure(err.message);
    }
  };

  const handleSuccess = () => {
    handleViewCard();
    setSuccess(!success);
  };

  const handleFailure = () => {
    handleViewCard();
    setFailure(!failure);
  };

  return (
    <>
      {success && (
        <SuccessPop
          message={t("SuccessPopUp.AddCryptoSuccess")}
          handleSuccess={handleSuccess}
          t={t}
        />
      )}
      {failure && (
        <FailurePopUp
          message={t("FailurePopUp.AddCryptoFailure")}
          handleFailure={handleFailure}
          t={t}
        />
      )}
      {!viewCard ? (
        <div className={styles.dropmenu}>
          <div className={styles.selezionaAltroAddress}>
            Seleziona altro address:
          </div>
          {Object.entries(addresses).flatMap(([coin, coinAddresses]) => {
            return Object.entries(coinAddresses).map(([index, address]) => {
              const formattedAddress =
                address.substring(0, 4) +
                "..." +
                address.substring(address.length - 5);

              return (
                <div key={`${coin}-${index}`}>
                  <img
                    className={styles.dropmenuChild}
                    alt=""
                    src="/line-3.svg"
                  />
                  <div
                    className={styles.addressDiv}
                    onClick={() => {
                      handleClickDropmenu();
                      handleCurrentAccount(
                        formattedAddress,
                        coin,
                        index,
                        address
                      );
                    }}
                  >
                    <b className={styles.coinType}>{`${coin} (${index})`}</b>
                    <div className={styles.mtdnscoxy}>{formattedAddress}</div>
                    <img
                      className={styles.vectorIcon}
                      alt=""
                      src="/vector.svg"
                    />
                  </div>
                </div>
              );
            });
          })}
          <div className={styles.button}>
            <div
              className={styles.mostraLeChiavi}
              onClick={() => setViewCard(!viewCard)}
            >
              AGGIUNGI ADDRESS
            </div>
          </div>
        </div>
      ) : (
        <DropExchange1
          handleViewCard={handleViewCard}
          generateAddress={handleGeneration}
          loading={loading}
        />
      )}
    </>
  );
};

export default Dropmenu;
