import React, { createContext, useContext, useEffect, useState } from 'react';

import { useCookies } from "react-cookie";
// Crea il contesto di autenticazione
const AuthContext = createContext();

// Provider per il contesto di autenticazione
const AuthProvider = ({ children }) => {
  
  const [token, setToken] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [cookies, setCookie] = useCookies(["accessToken"]);

  useEffect(() => {
    const existingToken = cookies.accessToken
    if (!existingToken) {
      setCookie('accessToken', null);
    } else {
      setToken(existingToken);
    }
    setIsInitialized(true);
  }, []);

  // Imposta il token come cookie e nello stato del contesto
  const setAuthToken = (newToken) => {
    setCookie('accessToken', newToken, { secure: true, sameSite: 'strict' });
    setToken(newToken);
  };

  // Rimuove il token dal cookie e dallo stato del contesto
  const removeAuthToken = () => {
    setToken(null);
  };

  return (
    <AuthContext.Provider value={{ token,isInitialized, setAuthToken, removeAuthToken }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook per accedere al contesto di autenticazione
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
