import { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import styles from "./styles/Faq.module.css";

const Faq = ({ t }) => {
  const questions = [
    {
      question: t("Faq.question1"),
      answer: t("Faq.answer1"),
    },
    {
      question: t("Faq.question2"),
      answer: t("Faq.answer2"),
    },
    {
      question: t("Faq.question3"),
      answer: t("Faq.answer3"),
    },
    {
      question: t("Faq.question4"),
      answer: t("Faq.answer4"),
    },
  ];

  return (
    <div className={styles.faq}>
      <div className={styles.faqAlcuneRisposteContainer}>
        <p className={styles.ilCryptowalletSelfCustody}>
          <b className={styles.faq2}>FAQ</b>
        </p>
        <p className={styles.alcuneRisposteAlle}>
          {t("Faq.someAnswersToYourQuestions")}
        </p>
      </div>
      {questions.map((item, index) => (
        <Accordion key={index}>
          <Accordion.Item eventKey={index} bsPrefix={styles.accordionItem}>
            <Accordion.Header bsPrefix={styles.accordionSetting}>
              {item.question}
            </Accordion.Header>
            <Accordion.Body bsPrefix={styles.accordionSettingButton}>
              {item.answer}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ))}
    </div>
  );
};

export default Faq;
