import React from 'react'
import styles from "./styles/SuccessPopUp.module.css";
import Error from '../error.png'
import { Button } from 'react-bootstrap'

const FailurePopUp = ({message,handleFailure,t}) => {
    return (
      <div className={styles.popupBox}>
      <div className={styles.box}>
        <div className={styles.upperInnerBox}>
          <img alt='failure' className={styles.checkLogo} src={Error} />
        </div>
        <div className={styles.bottomInnerBox}>
          <div className={styles.bottomLabel}>
            <h3>{t("FailurePopUp.titleCard")}</h3>
            <p>{message}</p>
            <Button className={styles.closeButton} onClick={handleFailure}>Chiudi</Button>
          </div>
        </div>
      </div>
    </div>
    )
}
export default FailurePopUp