import { BeatLoader } from "react-spinners";
import styles from "./styles/SidebarRight.module.css";

export const PortfolioCoins = ({portfolioCoins,loading}) => {
     return(
        <>
        {portfolioCoins.lenght==0?
            <div>
                <p>There is no recent transactions</p>
            </div>
        :
        <>
            <div className={styles.bitcoinTrans}>
            <img className={styles.vectorIcon1} alt="" src={portfolioCoins.src} />
            <div className={styles.title}>
                <div className={styles.bitcoinParent}>
                <div className={styles.bitcoin}>{portfolioCoins.name}</div>
                <div className={styles.btc}>{portfolioCoins.symbol}</div>
                </div>
            </div>
            <div className={styles.btc023455Parent}>
            {loading?
                    <BeatLoader size={8} color="#FFF"/>
                :
                <div className={styles.btc023455}>{portfolioCoins.balance}</div>}
            </div>
            </div>
        </>}
        </>
     )
};