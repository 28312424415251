import { createContext, useContext, useState } from "react";
import { toDataURL } from "qrcode";
import { useCookies } from "react-cookie";

const TwoFAContext = createContext();

export function use2FA() {
  return useContext(TwoFAContext);
}

export function TwoFAProvider({ children }) {
  const [toggleIsActive, setToggleIsActive] = useState(false);
  const [disactivateIsActive, setDisactivateIsActive] = useState(false);
  const [cookies, setCookie] = useCookies(["2FA"]);


  const handleSetToggleActive = () => {
    if(cookies["2FA"]!=="true"){
      setToggleIsActive(!toggleIsActive);
    } else{
      setDisactivateIsActive(!disactivateIsActive)
    }
  };

  const toggle2FA = (val) => {
    setCookie("2FA", val)
  };

  return (
    <TwoFAContext.Provider
      value={{
        disactivateIsActive,
        toggleIsActive,
        handleSetToggleActive,
        toggle2FA,
        toDataURL,
      }}
    >
      {children}
    </TwoFAContext.Provider>
  );
}
